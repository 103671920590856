.customer-support-section {
    & {
        padding: 5rem 2rem 0rem;
        background: #FFF;
        // width: 100%;
        // margin: 0 auto;

    }

    .customer-support {
        & {
            margin: 0 auto;
            max-width: 81.5rem;

        }

        .hero-section {
            & {
                margin-left: -3.175rem;
                display: flex;
                justify-content: space-between;
                // gap: 6.85rem;
            }

            .hero-headline {
                & {
                    position: relative;
                    color: #000;
                    font-family: "DM Sans";
                    font-size: 7.4375rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 7.5rem;
                    /* 100.84% */
                    letter-spacing: -0.22313rem;
                    max-width: 9ch;
                }

                .line-1 {
                    position: absolute;
                    bottom: 7rem;
                    left: 24rem;
                }

                .line-2 {
                    position: absolute;
                    bottom: -1rem;
                }
            }

            .hero-subtext-container {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    min-width: fit-content;
                }

                .hero-subtext {
                    margin: 0;
                    color: rgba(0, 0, 0, 0.70);
                    font-family: "DM Sans";
                    font-size: 1.225rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.8375rem;
                    /* 150% */
                    max-width: 25ch;
                }

                .hero-cta-container {
                    & {
                        display: flex;
                        gap: 1.25rem;
                        align-items: center;
                    }

                    .get-started-cta {
                        cursor: pointer;
                        border-radius: 0.1875rem;
                        // max-width: 13rem;
                        min-width: fit-content;
                        border: none;
                        // margin-top: 3rem;
                        background: #000;
                        display: flex;
                        padding: 1.25rem 2.81rem;
                        justify-content: center;
                        align-items: center;
                        gap: 0.6rem;
                        color: #FFF;
                        text-align: center;
                        font-family: "DM Sans";
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.5rem;
                        /* 150% */
                    }

                    .learn-more-2-cta {
                        min-width: fit-content;
                        // max-width: 9.5rem;
                        border: none;
                        color: #0A1953;
                        text-align: center;
                        font-family: "DM Sans";
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.5rem;
                        /* 150% */
                        background: rgba(255, 255, 255, 0.00);
                        box-shadow: 0px -2px 0px 0px rgba(0, 171, 214, 0.20) inset;
                    }
                }
            }
        }


    }

}

.inflexion-advantage-section {
    & {

        background: #F5F5F5;
        display: flex;
        flex-direction: column;
        gap: 5.86rem;
        padding: 8.54rem 2rem 11.85rem;
    }

    .inflexion-advantage {
        & {
            margin: 0 auto;
            max-width: 82.5rem;
            display: flex;
            flex-direction: column;
            gap: 5.86rem;
        }

        .advantage-header {
            margin: 0;
            color: #000;
            text-align: center;
            font-family: "DM Sans";
            font-size: 4.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: 5.625rem;
            /* 128.571% */
            letter-spacing: -0.0875rem;
        }

        .advantage-cards-container {
            & {
                display: flex;
                // flex-wrap: wrap;
                gap: 2.25rem;
                justify-content: space-between;
            }

            .advantage-card {
                & {
                    flex: 1 1 calc(33% - 1rem);
                    /* Adjust width as needed, e.g., 33% for three cards in a row */
                    display: flex;
                    flex-direction: column;
                    max-width: 100%;
                    padding: 0.5rem;
                    align-items: center;
                }

                .ant-card-body {
                    padding: 2.25rem 1rem 0 2.46rem;
                }

                .card-image {
                    margin-bottom: 2rem;
                }

                .card-text {
                    min-height: 14.5rem;
                    margin: 0;
                    color: #000;
                    font-family: "DM Sans";
                    font-size: 2.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2.625rem;
                    /* 116.667% */
                }

            }
        }

    }
}


.customer-support-section-3 {
    & {
        // max-width: 82.5rem;
    }

    .customer-support-3 {
        & {
            display: flex;
            gap: 4.5rem;
            align-items: center;

        }

        .left-image {
            max-width: 100%;
            height: auto;
        }

        .text-container {
            & {
                padding: 6rem;
                display: flex;
                flex-direction: column;
                gap: 4.5rem;
                font-family: "DM Sans";
                font-style: normal;
                color: #000;
            }

            .heading {
                margin: 0;
                font-size: 4.375rem;
                font-weight: 700;
                line-height: 4.375rem;
                /* 100% */
                letter-spacing: -0.0875rem;
                max-width: 10ch;
            }

            .subtext {
                margin: 0;
                font-size: 1.4875rem;
                font-weight: 500;
                line-height: 2.00813rem;
                /* 135% */
                max-width: 28ch;
            }
        }
    }
}

@media (max-width: 768px) {
    .inflexion-advantage-section .inflexion-advantage .advantage-cards-container .advantage-card {
        flex: 1 1 100%;
    }
}