.insights-section {
    padding: 5.4rem 2rem 0rem;
    max-width: 82.75rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 6rem;

    .header {
        margin: 0;
        color: #000;
        font-family: "DM Sans";
        font-size: 4.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4.375rem;
        /* 100% */
        letter-spacing: -0.0875rem;
    }

    .blog-cards-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-between;
    }

    .blog-card {
        background: #f5f5f5;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .custom-card-cover img {}

    .ant-card .ant-card-body {
        padding: 1rem 0 0 0;
    }

    .custom-card-title {
        color: #65A8D4;
        font-family: "DM Sans";
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.3125rem;
        /* 150% */
        text-transform: uppercase;

    }

    .custom-card-description {
        color: #0A1953;
        font-family: "DM Sans";
        font-size: 1.1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 140% */
    }

    .ant-card-cover img {
        width: 100%;
        // height: 200px;
        object-fit: cover;
    }

    @media (min-width: 576px) {
        .blog-card {
            width: calc(50% - 1rem);
            /* Two cards per row on small screens */
        }
    }

    @media (min-width: 768px) {
        .blog-card {
            width: calc(25% - 1rem);
            /* Four cards per row on medium and larger screens */
        }
    }

}