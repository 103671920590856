.hero-LP3-section {
    & {
        border-radius: 1.0625rem;
        background: #030303;
        padding: 4rem 0;
        position: relative;
        z-index: 2;

    }

    .hero-LP3 {
        & {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // max-width: 90rem;
            margin: 0 auto;
            position: relative;
            // overflow: hidden;
            // overflow-y: auto;

        }

        .left {
            & {
                margin-top: 4.5rem;
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                padding-left: 6rem;
                // flex: 1 0;
                position: relative;
                z-index: 2;
            }

            .pre-header {
                color: #969696;
                font-family: "Helvetica Neue";
                font-size: 1.25rem;
                font-style: normal;
                // font-weight: 400;
                line-height: normal;
                letter-spacing: 0.03125rem;
                text-transform: uppercase;
            }

            .header {
                margin: 0;
                color: #FFF;
                font-family: "Helvetica Now Display";
                font-size: 5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 5.625rem;
                /* 112.5% */
                letter-spacing: -0.05rem;
                max-width: 15ch;
            }

            .sub-headline {
                color: #CECECE;
                font-family: "Helvetica Neue";
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.875rem;
                /* 150% */
                max-width: 55ch;
            }

            .request-cta {
                font-family: 'Helvetica Neue';
                max-width: 13.8rem;
                border: none;
                margin-top: 2.5rem;
                display: flex;
                padding: 1.5625rem 1.625rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: 2.5rem;
                background: #2A71FA;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
            }

            .request-cta:hover {
                background: #dfdfdf;
                color: #2A71FA;
            }
        }

        .right {
            & {
                // flex: 1 0;
                max-width: fit-content;
                position: relative;
                // top: 2rem;
                // left: -8rem;
                // padding-right: 6rem;
            }
        }
    }

    .carousel-header {
        margin: 7.4rem auto 4.75rem;
        // margin-top: 7.4rem;

        max-width: fit-content;
        color: #BDBDBD;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.03125rem;
        text-transform: capitalize;
    }


    .LP3-gridSVG-container {
        margin-top: 1rem;
        display: flex;
        justify-content: center;
    }
}

@media (min-width: 1600px) {
    .right {
        padding-right: 6rem;
    }
}


@media (max-width:1200px) {
    .hero-LP3-section {
        .hero-LP3 {
            flex-direction: column;
            gap: 5rem;

            .left {
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                .pre-header {
                    font-size: 1rem;

                }

                .header {
                    font-size: 3rem;
                    line-height: 125%;
                    max-width: 90%;
                }

                .sub-headline {
                    font-size: 1rem;
                    line-height: 168.75%;
                }
            }

            .right {
                // padding-left: 1.5rem;
                // padding-right: 1.5rem;
                padding: 0;
                scale: 0.875;
            }
        }
    }
}

@media (max-width:768px) {}