.hero-how-it-works-section {
    & {
        border-radius: 1.0625rem;
        background: #030303;
        padding: 4rem 0;
        padding-top: 6rem;
    }

    .hero-how-it-works {
        & {
            display: flex;
            justify-content: space-between;
            // max-width: 90rem;
            margin: 0 auto;
            position: relative;
        }

        .left {
            & {
                margin-top: 4.5rem;
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                padding-left: 6rem;
                flex: 1 0;
                position: relative;
                z-index: 2;
            }

            .pre-header {
                color: #969696;
                font-family: "Helvetica Neue Light";
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.03125rem;
                text-transform: uppercase;
            }

            .header {
                margin: 0;
                color: #FFF;
                font-family: "Helvetica Now Display";
                font-size: 4.375rem;
                font-style: normal;
                font-weight: 700;
                line-height: 5.625rem;
                /* 112.5% */
                letter-spacing: -0.05rem;
                max-width: 18ch;
            }

            .sub-headline {
                color: #CECECE;
                font-family: "Helvetica Neue Light";
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                /* 150% */
                max-width: 56ch;
            }

            .get-started-cta {
                max-width: 13.8rem;
                border: none;
                margin-top: 2.5rem;
                display: flex;
                padding: 1.825rem 1.25rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: 2.5rem;
                background: #2A71FA;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                font-family: "Helvetica Neue";

            }

            .get-started-cta:hover {
                background: #dfdfdf;
                color: #2A71FA;
            }
        }

        .right {
            & {
                flex: 1 0;
                max-width: fit-content;
                margin-right: 3rem;
            }
        }
    }

    .carousel-header {
        margin: 7.4rem auto 4.75rem;
        // margin-top: 7.4rem;

        max-width: fit-content;
        color: #BDBDBD;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.03125rem;
        text-transform: capitalize;
    }


    .how-it-works-gridImg-container {
        margin-top: 1rem;
        display: flex;
        justify-content: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.scalable-teams-section {
    & {
        border-radius: 2rem;
        background: #FFF;

        /* M3/Elevation Light/1 */
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

    }

    .scalable-teams {
        & {
            padding: 4.5rem 6rem 8.3rem;
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 3.62rem;
        }

        .header-container {
            & {
                padding: 5.7rem 2rem 5.7rem;
                border-radius: 1.875rem;
                border: 1px solid #E6E6E6;
                display: flex;
                flex-direction: column;
                gap: 3.75rem;
                position: relative;
                align-items: center;
            }

            .img-l {
                position: absolute;
                top: 0;
                left: 0;

            }

            .img-r {
                position: absolute;
                top: 0;
                right: 0;
            }

            .pre-header {
                color: #393939;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.03125rem;
                text-transform: uppercase;
                font-family: "Helvetica Neue Light";

            }

            .header {
                z-index: 1;
                margin: 0;
                color: #1B1B1B;
                text-align: center;
                font-size: 3.5625rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.0625rem;
                font-family: 'Helvetica Now Display';
                /* 114.035% */
                max-width: 15ch;
            }

            .sub-header {
                margin: 0;
                margin-top: -0.5rem;
                color: #8B8B8B;
                text-align: center;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2.3125rem;
                /* 185% */
                max-width: 70ch;
                font-family: 'Helvetica Now Display';

            }
        }

        .scalable-teams-cards-container {
            & {
                display: flex;
                gap: 2.19rem;
                justify-content: center;
                align-items: center;
                padding: 2rem 6rem;
            }

            .card {
                & {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 3.5rem;
                    padding: 5rem 5.75rem 4.25rem 3.3rem;
                    border-radius: 1.875rem;
                    border: 1px solid #E6E6E6;
                    background: #F7F7F7;
                }

                .pre-header {
                    color: #393939;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.03125rem;
                    text-transform: uppercase;
                    font-family: 'Helvetica Neue';
                }

                .header {
                    margin: 0;
                    color: #1B1B1B;
                    font-size: 2.25rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2.8125rem;
                    /* 125% */
                    max-width: 18ch;
                    font-family: 'Helvetica Now Display';
                }

                .sub-text {
                    min-height: 14.5rem;

                    margin: 0;
                    color: #595959;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2.3125rem;
                    /* 185% */
                    max-width: 45ch;
                    font-family: 'Helvetica Now Display';

                }
            }
        }
    }
}

.our-process-section {
    & {
        border-radius: 1.0625rem;
        background: #030303;
        padding: 15rem 6rem;
        position: relative;
    }

    .dots-l {
        position: absolute;
        top: 0;
        left: 0;
    }

    .dots-r {
        position: absolute;
        top: 0;
        right: 0;
        transform: rotateY(180deg);
    }

    .process-container {
        & {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
        }



        .header-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 4.12rem;
                align-items: center;
                text-align: center;
                position: relative;
                padding-bottom: 9.25rem;
            }



            .header {
                margin: 0;
                color: #FFF;
                text-align: center;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
                /* 118.75% */
                text-transform: capitalize;
                font-family: 'Helvetica Now Display';
            }

            .sub-text {
                color: #CECECE;
                text-align: center;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2rem;
                /* 160% */
                letter-spacing: 0.025rem;
                font-family: 'Helvetica Now Display';
            }
        }
    }
}

.faqs-section {
    & {
        margin-top: 1.5rem;
        padding: 7.8rem 6rem 12.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        border: 1px solid #393939;
        background: #2A71FA;
        position: relative;
        z-index: 3;
    }

    .faqs-container {
        & {
            display: flex;
            flex-direction: column;
            gap: 4rem;
            align-items: center;
        }

        .header-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 4.2rem;
                align-items: center;
            }

            .pre-header {
                color: rgba(255, 255, 255, 0.70);
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                font-family: 'Helvetica Neue Light';
            }

            .header {
                margin: 0;
                color: #FFF;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
                /* 118.75% */
                text-transform: capitalize;
                font-family: 'Helvetica Now Display';
            }
        }

        .faqs-collapse-container {
            & {
                padding-top: 4rem;
            }
        }
    }
}

.footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #030303;
    color: white;
    z-index: 1;
    /* Initially behind the content */
    display: none;
    /* Initially hidden */
}

.footer.show {
    display: block;
    /* Show the footer when the class 'show' is added */
}


@media (max-width: 1200px) {
    .hero-how-it-works-section {
        .hero-how-it-works {
            & {
                flex-direction: column;
                align-items: center;
                gap: 5rem;
            }

            .left {
                margin-top: 0;
                padding-left: 0;
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                .pre-header {
                    font-size: 1rem;

                }

                .header {
                    font-size: 3rem;
                    line-height: 122.5%;
                    max-width: 80%;
                }

                .sub-headline {
                    font-size: 1rem;
                    line-height: 1.625rem;
                    /* 162.5% */

                }
            }

            .right {
                margin: 0;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }

    .scalable-teams-section {
        .scalable-teams {
            padding-left: 1.5rem;
            padding-right: 1.5rem;

            .header-container {
                & {
                    gap: 2.5rem;
                    padding-bottom: 3.5rem;
                }

                .img-l,
                .img-r {
                    z-index: 1;
                    display: none;
                }

                .pre-header {
                    position: relative;
                    z-index: 2;
                }

                .header {
                    font-size: 2.25rem;
                    line-height: 132.5%;
                }

                .sub-header {
                    font-size: 1rem;
                    line-height: 150%;
                }
            }

            .scalable-teams-cards-container {
                & {
                    flex-direction: column;
                    padding: 0rem;
                }

                .card {
                    padding: 2.5rem 2rem 3rem;
                    gap: 2.5rem;

                    .pre-header {
                        font-size: 0.78744rem;

                    }

                    .header {
                        font-size: 2rem;
                        line-height: 130%;
                    }

                    .sub-text {
                        font-size: 1rem;
                        line-height: 150%;
                        min-height: auto;
                    }
                }
            }
        }
    }

    .our-process-section {
        padding: 8rem 1.5rem;

        .process-container {
            .header-container {
                gap: 2.5rem;
                padding-bottom: 5rem;

                .header {
                    font-size: 2.625rem;
                    line-height: 133%;
                    max-width: 80%;
                }

                .sub-text {
                    margin: 0;
                    font-size: 1rem;
                    line-height: 150%;
                }
            }
        }
    }

    .faqs-section {
        & {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-bottom: 9rem;
        }

        .faqs-container {
            & {}

            .header-container {
                & {
                    align-items: flex-start;
                    gap: 3rem;

                }

                .pre-header {
                    font-size: 1.25rem;
                }

                .header {
                    font-size: 2.625rem;
                    line-height: 133.33%;
                }
            }

            .faqs-collapse-container {
                .ant-collapse {
                    & {
                        padding: 4rem 1.5rem;

                    }


                    .faq-collapse-panel {
                        .ant-collapse-header {
                            padding: 3rem 0rem;

                            .ant-collapse-header-text {
                                font-size: 1.25rem;
                                line-height: 150%;
                            }
                        }

                        .ant-collapse-content-box {
                            padding-left: 0rem;
                            padding-right: 0rem;
                        }
                    }

                }
            }
        }
    }

    .feature-7-LP3-section {
        & {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .feature-7-LP3 {
            .header-container {
                .header {
                    font-size: 2.625rem;
                    line-height: 133.33%;
                }
            }
        }
    }
}

@media (max-width: 768px) {}