.data-management-section {
    & {
        padding: 5rem 2rem 13.5rem;
        background: #FFF;
        // width: 100%;
        // margin: 0 auto;

    }

    .data-management {
        & {
            margin: 0 auto;
            max-width: 81.5rem;

        }

        .hero-section {
            & {
                margin-left: -3.175rem;
                display: flex;
                justify-content: space-between;
                // gap: 6.85rem;
            }

            .hero-headline {
                & {
                    position: relative;
                    color: #000;
                    font-family: "DM Sans";
                    font-size: 7.4375rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 7.5rem;
                    /* 100.84% */
                    letter-spacing: -0.22313rem;
                    max-width: 9ch;
                }

                .line-1 {
                    position: absolute;
                    bottom: 7rem;
                    left: 24rem;
                }

                .line-2 {
                    scale: 0.9;
                    position: absolute;
                    // top: 6.4rem;
                    left: -2rem;
                    // right: 1rem;
                    bottom: 0rem;

                }
            }

            .hero-subtext-container {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    min-width: fit-content;
                }

                .hero-subtext {
                    margin: 0;
                    color: rgba(0, 0, 0, 0.70);
                    font-family: "DM Sans";
                    font-size: 1.225rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.8375rem;
                    /* 150% */
                    max-width: 30ch;
                }

                .hero-cta-container {
                    & {
                        display: flex;
                        gap: 1.25rem;
                        align-items: center;
                    }

                    .get-started-cta {
                        cursor: pointer;
                        border-radius: 0.1875rem;
                        // max-width: 13rem;
                        min-width: fit-content;
                        border: none;
                        // margin-top: 3rem;
                        background: #000;
                        display: flex;
                        padding: 1.25rem 2.81rem;
                        justify-content: center;
                        align-items: center;
                        gap: 0.6rem;
                        color: #FFF;
                        text-align: center;
                        font-family: "DM Sans";
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.5rem;
                        /* 150% */
                    }

                    .learn-more-2-cta {
                        min-width: fit-content;
                        // max-width: 9.5rem;
                        border: none;
                        color: #0A1953;
                        text-align: center;
                        font-family: "DM Sans";
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.5rem;
                        /* 150% */
                        background: rgba(255, 255, 255, 0.00);
                        box-shadow: 0px -2px 0px 0px rgba(0, 171, 214, 0.20) inset;
                    }
                }
            }
        }



    }
}

.data-management-cards-section {
    & {
        padding: 6.6rem 0 7.5rem;
        background: #f5f5f5;

        display: flex;
        flex-direction: column;
        gap: 7rem;
        align-items: center;
        // margin: 0 auto;
    }

    .header-container {
        & {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            font-family: "DM Sans";
        }

        .header {
            margin: 0;
            color: #000;
            text-align: center;
            font-size: 4.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: 5.625rem;
            /* 128.571% */
            letter-spacing: -0.0875rem;
        }

        .sub-text {
            margin: 0;
            color: rgba(0, 0, 0, 0.70);
            font-size: 1.225rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.8375rem;
            text-align: center;
            /* 150% */
        }
    }

    .data-management-cards-container {
        & {
            max-width: 82.5rem;
            margin: 0 auto;
            display: flex;
            // flex-wrap: wrap;
            gap: 2.25rem;
            justify-content: space-between;
        }

        .data-management-card {
            & {
                flex: 1 1 calc(33% - 1rem);
                /* Adjust width as needed, e.g., 33% for three cards in a row */
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0.5rem;
                align-items: center;
            }

            .ant-card-body {
                padding: 2.25rem 1rem 0 2.46rem;
            }

            .card-image {
                margin-bottom: 3.8rem;
            }

            .card-text {
                min-height: 26.5rem;
                margin: 0;
                color: #000;
                font-family: "DM Sans";
                font-size: 2.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2.625rem;
                /* 116.667% */
            }

        }
    }
}



@media (max-width: 768px) {
    .data-management-cards-section .data-management-cards-container .data-management-card {
        flex: 1 1 100%;
    }
}