.hire-support-hero-section {
    & {
        background: #030303;
        position: relative;
        z-index: 2;
    }

    .hire-support-hero {
        & {
            border-radius: 1.0625rem;
            background: #030303;
            padding: 4rem 0;
            padding-top: 6rem;
        }

        .hero-hire-support {
            & {
                display: flex;
                justify-content: space-between;
                // max-width: 90rem;
                margin: 0 auto;
                position: relative;
            }

            .left {
                & {
                    // margin-top: 4.5rem;
                    display: flex;
                    flex-direction: column;
                    gap: 2.5rem;
                    padding-left: 6rem;
                    flex: 1 0;
                    position: relative;
                    z-index: 2;
                }

                .pre-header {
                    color: #969696;
                    font-family: "Helvetica Neue";
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.03125rem;
                    text-transform: uppercase;
                }

                .header {
                    margin: 0;
                    color: #FFF;
                    font-size: 4.375rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.75rem;
                    /* 108.571% */
                    max-width: 15ch;
                    font-family: 'Helvetica Now Display';
                }

                .sub-headline {
                    color: #CECECE;
                    font-family: "Helvetica Neue Light";
                    font-size: 1.3rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                    /* 150% */
                    max-width: 40ch;
                }

                .what-you-recieve {
                    & {}

                    .text {
                        color: #676767;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 2.125rem;
                        font-family: "Helvetica Neue Light";

                        /* 170% */
                    }

                    .list-container {
                        & {
                            display: flex;
                            flex-direction: column;
                            gap: 1.25rem;
                            padding-left: 0;
                        }

                        .list-item {
                            color: #FFF;
                            font-size: 1.25rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 2.125rem;
                            /* 170% */
                            align-items: center;
                            gap: 1.75rem;
                            display: flex;
                            font-family: "Helvetica Now Display";

                        }
                    }
                }

                .get-started-cta {
                    font-family: "Helvetica Now Display";

                    max-width: 16rem;
                    border: none;
                    // margin-top: 2.5rem;
                    display: flex;
                    padding: 1.625rem 1.625rem;
                    justify-content: center;
                    align-items: center;
                    gap: 0.625rem;
                    border-radius: 2.5rem;
                    background: #2A71FA;
                    color: #FFF;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }

                .get-started-cta:hover {
                    background: #dfdfdf;
                    color: #2A71FA;
                }
            }

            .right {
                & {
                    flex: 1 0;
                    max-width: fit-content;
                    margin-right: 3rem;
                    // margin-top: 5rem;
                }


            }
        }

        .carousel-header {
            margin: 7.4rem auto 4.75rem;
            // margin-top: 7.4rem;

            max-width: fit-content;
            color: #BDBDBD;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.03125rem;
            text-transform: capitalize;
        }


        .how-it-works-gridImg-container {
            margin-top: 1rem;
            display: flex;
            justify-content: center;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

.hire-support-features-section {
    & {
        border-radius: 2rem;
        background: #FFF;
        // fill: #FFF;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.30));
        position: relative;
        z-index: 2;
    }

    .hire-support-features {
        & {
            padding: 10rem 6rem 14rem;
            display: flex;
            flex-direction: column;
            gap: 11rem;
        }

        .feature1 {
            & {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8rem;
            }

            .header {
                margin: 0;
                text-align: center;
                color: #010101;
                text-align: center;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 5.0625rem;
                /* 126.563% */
                max-width: 22ch;
                font-family: "Helvetica Now Display";

            }

            .feature1-cards-container {
                & {
                    display: flex;
                    gap: 2rem;
                    max-width: 90%;
                }

                .feature1-card {
                    & {
                        flex: 1 1 calc(33% - 1rem);
                        /* Adjust width as needed, e.g., 33% for three cards in a row */
                        display: flex;
                        flex-direction: column;
                        max-width: 100%;
                        padding: 0.5rem;
                        align-items: center;
                        border-radius: 1.875rem;
                        border: 1px solid #C5C5C5;
                    }

                    .ant-card-body {
                        padding: 2.25rem 1rem 0 1.75rem;

                        .feature-header {
                            color: #010101;
                            font-size: 2rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 2.8125rem;
                            max-width: 16ch;
                            font-family: "Helvetica Now Display";

                            /* 140.625% */
                        }

                        .feature-subtext {
                            color: #616161;
                            font-size: 1.25rem;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 2rem;
                            /* 160% */
                            max-width: 27.5ch;
                            font-family: "Helvetica Now Display";

                        }
                    }
                }
            }
        }

        .feature2 {
            & {
                display: flex;
                flex-direction: column;
                gap: 8.19rem;
                align-items: center;
            }

            .header {
                margin: 0;
                color: #010101;
                text-align: center;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 5.0625rem;
                /* 126.563% */
                font-family: "Helvetica Now Display";

                max-width: 24ch;
            }

            .subtext {
                margin: 0;
                color: #616161;
                text-align: center;
                font-size: 1.25rem;
                font-style: normal;
                line-height: 2.1875rem;
                /* 175% */
                max-width: 60ch;
                font-family: "Helvetica Now Display";

            }

            .feature2-cards-container {
                & {
                    margin-top: 5rem;
                    display: flex;
                    flex-direction: column;
                    // align-items: flex-end;
                    max-width: 90rem;
                    gap: 9rem;
                    // justify-content: center;
                }

                .feature2-card {
                    // max-width: fit-content;
                    display: flex;
                    // justify-content: space-between;
                    gap: 8.75rem;

                    .left {
                        width: 50%;
                    }

                    .right {
                        width: 50%;
                    }

                    h2 {
                        margin: 1.75rem 0;
                        color: #010101;
                        font-size: 2.5rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 3.0625rem;
                        font-family: "Helvetica Now Display";
                        max-width: 18ch;
                        /* 136.111% */
                        text-transform: capitalize;
                    }

                    p {
                        margin: 2rem 0;
                        color: #565656;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2.1875rem;
                        font-family: "Helvetica Now Display";

                        /* 175% */
                        max-width: 55ch;
                    }

                }

            }
        }
    }
}

.stats-section {
    & {
        background: #030303;
        position: relative;
        z-index: 2;
    }

    .stats {
        & {
            display: flex;
            align-items: center;
            // max-width: 106rem;
            padding: 11rem 6rem 13rem;
            justify-content: space-between;
            gap: 12rem;
            margin: 0 auto;
        }

        .stats-text {
            & {
                display: flex;
                flex-direction: column;
                width: 50%;
                gap: 5rem;
                max-width: fit-content;
            }

            .pre-header {
                font-family: "Helvetica Neue";

                color: #969696;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.03125rem;
                text-transform: uppercase;
            }

            .header {
                margin: 0;
                color: #FFF;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 5.46875rem;
                /* 136.719% */
                letter-spacing: 0.08rem;
                text-transform: capitalize;
                font-family: "Helvetica Now Display";

            }

            .subtext {
                margin: 0;
                color: #B7BBC3;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.625rem;
                /* 210% */
                letter-spacing: 0.025rem;
                font-family: "Helvetica Now Display";

                // text-transform: capitalize;
            }

            .free-quote-cta {
                display: flex;
                max-width: 15.8125rem;
                padding: 1.5625rem 1.625rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: 2.5rem;
                background: #2A71FA;
                border: 1px solid #2A71FA;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                font-family: "Helvetica Neue";

            }

            .free-quote-cta:hover {
                background: #030303;
                color: #FFF;
            }
        }

        .stats-metric {
            & {
                display: grid;
                gap: 3rem;
                width: 50%;
                max-width: fit-content;
                padding: 3rem 7.275rem 0rem 3rem;
                // padding-bottom: 0;
                border-radius: 2.125rem;
                border: 1px solid #4D4D4D;
                background: rgba(255, 255, 255, 0.05);
                grid-template-columns: repeat(1, 4fr);

            }


            .stat-container {
                & {
                    padding-bottom: 3rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;
                    // border-bottom: 1px solid #4d4d4d;
                }

                .stat-header {
                    margin: 0;
                    font-size: 4rem;
                    font-style: normal;
                    font-weight: 700;
                    letter-spacing: 0.08rem;
                    text-transform: capitalize;
                    font-family: "Helvetica Now Display";

                }

                .stat-subtext {
                    color: #FFF;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: 0.03rem;
                    font-family: "Helvetica Now Display";
                    // max-width: 15ch;

                }
            }

            .container4 {
                border-bottom: none !important;
            }
        }
    }
}


.hire-support-section {
    & {
        padding: 5rem 0rem;
        display: flex;
        position: relative;
        z-index: 2;
    }

    .get-started-section {
        & {
            border-radius: 2rem;
            border: 1px solid #393939;
            background: #2A71FA;
            color: white;
            width: 100%;

        }

        .get-started {
            & {
                margin: 0 auto;
                padding: 8.19rem 6rem 10.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 7rem;
                max-width: 80rem;
            }

            .header {
                margin: 0;
                color: #FFF;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
                /* 118.75% */
                text-transform: capitalize;
                max-width: 55rem;
                text-align: center;
                font-family: 'Helvetica Now Display';
            }

            .get-started-details {
                & {
                    display: flex;
                    gap: 2.3rem;
                    justify-content: center;
                }

                .details-text {
                    & {
                        display: flex;
                        flex-direction: column;
                        gap: 2.2rem;
                        min-width: 50%;
                    }


                    .text {
                        padding: 2.6875rem 1.8125rem;
                        border-radius: 1.6875rem;
                        background: rgba(255, 255, 255, 0.15);
                        display: flex;
                        flex-direction: column;
                        font-family: 'Helvetica Now Display';
                    }

                    .text-1 {
                        & {
                            gap: 3rem;
                            position: relative;
                            background: #FFF;
                            color: #111;
                        }

                        .heading {
                            margin: 0;
                            color: #111;
                            font-size: 2.25rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 2.875rem;
                            /* 127.778% */
                            text-transform: capitalize;
                            max-width: 30ch;
                        }

                        .sub-text {
                            margin: 0;
                            color: #5d5d5d;
                            font-size: 1.25rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 2rem;
                            /* 160% */
                            // text-transform: capitalize;
                            max-width: 45ch;

                        }
                    }

                    .text-2 {
                        & {
                            gap: 2rem;
                        }

                        .heading {
                            margin: 0;
                            color: #FFF;
                            font-size: 1.6875rem;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 4.75rem;
                            /* 281.481% */
                            text-transform: capitalize;
                            font-family: 'Helvetica Now Display';

                        }

                        .list-container {
                            & {
                                display: flex;
                                flex-direction: column;
                                gap: 1.5rem;

                            }

                            .list-item {
                                display: flex;
                                align-items: center;
                                gap: 1.75rem;
                                color: #FFF;
                                font-size: 1.25rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 2.125rem;
                                /* 170% */
                                max-width: 45ch;
                                font-family: 'Helvetica Now Display';

                            }
                        }
                    }
                }

                .popup-cta-container {
                    & {
                        border-radius: 1.9375rem;
                        border: 1px solid rgba(245, 245, 245, 0.40);
                        background: #FFF;
                        padding: 3.5rem;
                        color: #0B0B0B;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

                    .heading {
                        color: #0B0B0B;
                        margin: 0;
                        // margin-top: rem;
                        font-size: 3.5rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 4.75rem;
                        /* 135.714% */
                        text-transform: capitalize;
                        font-family: 'Helvetica Now Display';
                    }

                    .outsource-img-container {
                        display: flex;
                        flex-direction: column;
                        gap: 3rem;

                        .agent-val-cell:hover {
                            background: #030303 !important;
                            color: white !important;
                        }
                    }

                    .outsource-img-text {
                        color: #3E3E3E;
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 125%;
                        margin: 0;
                        font-family: 'Helvetica Now Display';
                    }

                    .start-cta {
                        display: flex;
                        padding: 1.75625rem 1.625rem;
                        justify-content: center;
                        align-items: center;
                        gap: 0.625rem;
                        border-radius: 2.5rem;
                        background: #030303;
                        color: #FFF;
                        font-size: 1.5rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        border: 1px solid #030303;
                    }

                    .start-cta:hover {
                        background: #FFF;
                        color: #030303;
                    }


                }


            }
        }
    }
}

.ant-modal-mask {
    background: rgba(37, 37, 37, 0.50);
    backdrop-filter: blur(17px);
}

.ant-modal .ant-modal-content {
    border-radius: 2rem;
    padding: 0;

    .ant-modal-close {
        position: absolute;
        right: 1.81rem;
        top: 1.56rem;
    }

    .ant-modal-close:hover {
        background: none;
    }
}


.ant-modal-root .ant-modal-wrap {
    overflow: none;
}

.ant-modal-root .ant-modal-centered .ant-modal {
    width: 80rem !important;
    // scale: 0.85;
}

.footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #030303;
    color: white;
    z-index: 1;
    /* Initially behind the content */
    display: none;
    /* Initially hidden */
}

.footer.show {
    display: block;
    /* Show the footer when the class 'show' is added */
}


@media (max-width: 1400px) {

    .stats-section .stats .stats-metric {
        padding-right: 4.5rem !important;
    }

    .hire-support-features-section .hire-support-features .feature1 .feature1-cards-container {
        max-width: 100% !important;
    }

    // .hire-support-section .get-started-section .get-started .get-started-details .popup-cta-container .outsource-img-container .agent-val-grid .agent-val-cell {
    //     font-size: 1rem !important;
    //     padding: 1rem !important;
    // }

    .hire-support-hero-section {
        .hire-support-hero {
            .hero-hire-support {
                .left {
                    & {
                        gap: 3.25rem;
                    }

                    .pre-header {
                        font-size: 1rem;
                    }

                    .header {
                        font-size: 3.5rem;
                    }

                    .sub-headline {
                        font-size: 1.25rem;
                        max-width: 48ch;
                    }

                    .what-you-recieve {
                        .text {
                            font-size: 1.25rem;
                        }

                        .list-container {
                            padding-top: 2rem;

                            .list-item {
                                font-size: 1.1rem;
                                line-height: 150%;
                            }
                        }
                    }

                    .get-started-cta {
                        padding: 2rem 1.625rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .hire-support-hero {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .hire-support-hero-section .hire-support-hero .hero-hire-support {
        flex-direction: column;
        gap: 6rem;
        align-items: center;

        .left {
            padding-left: 0;
        }

        .right {
            margin-right: 0;
        }
    }

    .hire-support-features-section .hire-support-features {
        & {
            padding: 7.5rem 1.5rem 8.5rem;
            gap: 9rem !important;
        }

        .feature1 {
            gap: 6rem !important;
        }

        .header {
            font-size: 3.25rem !important;
            line-height: 127.5% !important;
        }

        .feature1-cards-container {
            flex-direction: column;
        }

        .ant-card-body {
            .feature-header {
                max-width: 24ch !important;
            }

            .feature-subtext {
                max-width: 42ch !important;
            }
        }

        .feature2 {
            .header {
                font-size: 3.25rem !important;
                line-height: 127.5% !important;
            }

            .subtext {
                max-width: 48ch;
            }

            .feature2-cards-container {
                & {
                    margin-top: 2rem;
                    align-items: center;
                }

                .feature2-card {
                    flex-direction: column;
                    align-items: center;
                    gap: 3rem;
                }


                .card1,
                .card3 {
                    .left {
                        width: 100% !important;
                        justify-content: center;
                    }

                    .right {
                        width: 80% !important;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .card2 {
                    .left {
                        order: 2;
                        width: 80% !important;
                        justify-content: center;

                    }

                    .right {
                        width: 100% !important;
                        justify-content: center;
                    }
                }
            }
        }
    }

    .stats-section .stats {
        & {
            flex-direction: column;
            padding: 8rem 1.5rem;
            gap: 6rem;
        }

        .stats-text {
            width: 80%;
            gap: 4rem;

            .header {
                font-size: 3.5rem;
            }
        }

        .stats-metric {
            width: 80%;
            max-width: 100%;
            align-items: center;
            // align-items: center;
            grid-template-columns: repeat(2, 2fr);
            padding-left: 2.5rem;
            padding-right: 2.5rem !important;
        }
    }

    .hire-support-section .get-started-section .get-started {
        & {
            padding: 7rem 1.5rem !important;
        }

        .header {
            font-size: 3.5rem;
            max-width: 20ch;
        }

        .get-started-details {
            & {
                flex-direction: column;
            }

            .details-text .text-2 .list-container .list-item {
                max-width: none;
            }

            .popup-cta-container {
                // padding: 3rem 2rem;
                gap: 5rem;
            }
        }
    }
}

@media (max-width: 768px) {
    .ant-modal-root .ant-modal-centered .ant-modal {
        width: 25rem !important;
        // scale: 0.85;
    }


    .hire-support-hero-section .hire-support-hero .hero-hire-support {
        .left {
            .pre-header {
                line-height: 168%;
            }

            .header {
                font-size: 3rem;
                line-height: 122.97%;
            }


        }
    }

    .hire-support-features-section .hire-support-features {
        & {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .feature1 {
            & {
                gap: 5rem;
            }

            .header {
                font-size: 2.5rem !important;
                line-height: 127.5% !important;
            }

            .feature1-cards-container {
                .feature-header {
                    font-size: 1.75rem !important;
                    line-height: 155% !important;
                    max-width: none !important;
                }

                .feature-subtext {
                    font-size: 1rem !important;
                    line-height: 193.5% !important;
                    max-width: none !important;
                }
            }
        }

        .feature2 {
            .header {
                font-size: 2.5rem !important;
                line-height: 133.33% !important;
                max-width: none;
            }

            .subtext {
                font-size: 1rem !important;
                line-height: 150% !important;
                padding: 0 0.75rem;
            }

            .feature2-cards-container {

                .card1,
                .card3 {
                    .right {
                        width: 95% !important;

                        h2 {
                            font-size: 2rem !important;
                            line-height: 150% !important;
                        }

                        p {
                            font-size: 1rem !important;
                            line-height: 150% !important;
                        }
                    }
                }

                .card2 {
                    .left {
                        width: 95% !important;

                        h2 {
                            font-size: 2rem !important;
                            line-height: 150% !important;
                        }

                        p {
                            font-size: 1rem !important;
                            line-height: 150% !important;
                        }
                    }
                }
            }
        }

    }

    .stats-section .stats {
        .stats-text {
            width: 100% !important;

            .header {
                font-size: 2.625rem;
                line-height: 133.33%;
            }

            .subtext {
                font-size: 1rem !important;
                line-height: 150%;
            }
        }

        .stats-metric {
            grid-template-columns: repeat(1, 4fr);
            align-items: center;
        }
    }

    .hire-support-section .get-started-section .get-started {
        .header {
            font-size: 2.625rem !important;
            line-height: 133.33% !important;
        }

        .get-started-details {
            .details-text {
                .text-1 {
                    gap: 2rem;

                    .heading {
                        font-size: 1.75rem !important;
                        line-height: 103% !important;
                    }

                    .sub-text {
                        font-size: 1rem !important;
                        line-height: 150%;
                    }

                    img {
                        scale: 0.75;
                        right: -1rem !important;
                    }
                }

                .text-2 {
                    gap: 2.25rem;

                    .heading {
                        font-size: 1.3125rem !important;
                        line-height: 150% !important;
                    }

                    .list-container .list-item {
                        font-size: 1rem !important;
                        line-height: 150% !important;
                    }

                }


            }

            .popup-cta-container {
                padding: 3rem 2rem 2rem;

                .heading {
                    font-size: 2.2395rem;
                    line-height: 3.03931rem;
                    /* 135.714% */

                }

                .outsource-img-container {

                    .outsource-img-text {
                        font-size: 1rem;
                        line-height: 150%;
                    }

                    .agent-val-cell {
                        font-size: 0.8rem !important;
                        padding: 0.84rem !important;

                    }
                }
            }
        }
    }

}