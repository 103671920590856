.feature-1-LP3-section {
    & {
        border-radius: 2rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // background: #FFF !important;

        /* M3/Elevation Light/1 */
        // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
        padding-top: 6.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .feature-1-LP3 {
        & {
            margin: 0 auto;
            padding-bottom: 6rem;
        }

        .header-container {
            & {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 3.75rem;
                font-family: "Helvetica Neue";

            }

            .pre-header {
                color: #393939;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.03125rem;
                text-transform: uppercase;
            }

            .header {
                font-family: 'Helvetica Now Display';
                margin: 0;
                color: #141414;
                text-align: center;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 5rem;
                /* 125% */
                text-transform: capitalize;
                max-width: 22ch;
            }
        }


        // .feature-1-cards-container {
        //     & {
        //         max-width: 86.2rem;
        //         padding-top: 8.5rem;
        //         display: flex;
        //         // flex-wrap: wrap;
        //         gap: 2rem;
        //         align-items: center;
        //         justify-content: center;
        //         // margin: 0 auto;

        //     }

        //     .card {
        //         .ant-card-body {
        //             padding: 0;
        //             // border: none !important;
        //             min-width: 24.75rem;

        //         }

        //         & {
        //             background: #F7F7F7;

        //             align-items: center;
        //             // flex: 1 0;
        //             flex: 1 1 calc(33% - 1rem);
        //             width: 33%;
        //             /* Adjust width as needed, e.g., 33% for three cards in a row */
        //             display: flex;
        //             flex-direction: column;
        //             max-width: 100%;
        //             // padding: 0.5rem;
        //             // align-items: center;
        //             max-width: fit-content;
        //             border-radius: 1.75rem;
        //             border: none;
        //             // border: 1px solid #CBCBCB;

        //         }

        //         .card-upper {
        //             & {
        //                 position: relative;
        //                 z-index: 2;
        //                 padding: 3.25rem 1.88rem 2.75rem;
        //                 display: flex;
        //                 flex-direction: column;
        //                 gap: 2.5rem;
        //                 border-radius: 1.75rem;
        //                 border: 1px solid #D9D9D9;
        //                 background: #FFF;
        //                 // min-height: 20.31rem;
        //             }

        //             .upper-img {
        //                 margin-bottom: 0.25rem;
        //             }

        //             .header {
        //                 margin: 0;
        //                 color: #1B1B1B;
        //                 font-size: 2.25rem;
        //                 font-style: normal;
        //                 font-family: 'Helvetica Now Display';
        //                 font-weight: 700;
        //                 line-height: 2.8125rem;
        //                 /* 125% */
        //                 max-width: 15ch;
        //             }

        //             .sub-headline {
        //                 font-family: "Helvetica Now Display";
        //                 margin: 0;
        //                 color: #878787;
        //                 font-size: 1.225rem;
        //                 font-style: normal;
        //                 font-weight: 500;
        //                 line-height: 2rem;
        //                 /* 180% */
        //                 max-width: 28ch;

        //             }

        //         }

        //         .card-lower {
        //             & {
        //                 margin-top: -2.5rem;
        //                 position: relative;
        //                 z-index: 1;
        //                 border-radius: 1.5rem;
        //                 border: 1px solid #CBCBCB;
        //                 background: #F7F7F7;
        //                 padding-top: 5rem;
        //                 padding-left: 1.3rem;
        //                 padding-right: 0.75rem;
        //                 padding-bottom: 3rem;
        //             }

        //             ul {
        //                 font-family: 'Helvetica Now Display';
        //                 // list-style-type: square;
        //                 margin: 0;
        //                 color: #303030;
        //                 font-size: 1.125rem;
        //                 font-style: normal;
        //                 font-weight: 500;
        //                 line-height: 2rem;
        //                 /* 177.778% */
        //                 letter-spacing: 0.0225rem;

        //                 li {
        //                     width: 28ch;
        //                 }
        //             }
        //         }
        //     }
        // }
        .feature-1-cards-container {
            max-width: 86.2rem;
            padding-top: 8.5rem;
            display: flex;
            gap: 2rem;
            align-items: center;
            justify-content: center;

            .card {
                background: #F7F7F7;
                display: flex;
                flex-direction: column;
                max-width: 100%;
                border-radius: 1.75rem;
                border: none;
                flex: 1; // Ensures equal width for each card
                cursor: auto;

                .ant-card-body {
                    padding: 0;
                    min-width: 24.75rem;
                }

                .card-upper {
                    position: relative;
                    z-index: 2;
                    padding: 3.25rem 1.88rem 2.75rem;
                    display: flex;
                    flex-direction: column;
                    gap: 2.5rem;
                    border-radius: 1.75rem;
                    border: 1px solid #D9D9D9;
                    background: #FFF;

                    .upper-img {
                        margin-bottom: 0.25rem;
                    }

                    .header {
                        margin: 0;
                        color: #1B1B1B;
                        font-size: 2.25rem;
                        font-style: normal;
                        font-family: 'Helvetica Now Display';
                        font-weight: 700;
                        line-height: 2.8125rem;
                        max-width: 15ch;
                    }

                    .sub-headline {
                        font-family: "Helvetica Now Display";
                        margin: 0;
                        color: #878787;
                        font-size: 1.225rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2rem;
                        max-width: 28ch;
                    }
                }

                .card-lower {
                    margin-top: -2.5rem;
                    position: relative;
                    z-index: 1;
                    border-radius: 1.5rem;
                    border: 1px solid #CBCBCB;
                    background: #F7F7F7;
                    padding-top: 5rem;
                    padding-left: 1.3rem;
                    padding-right: 0.75rem;
                    padding-bottom: 3rem;

                    ul {
                        font-family: 'Helvetica Now Display';
                        margin: 0;
                        color: #303030;
                        font-size: 1.125rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2rem;
                        letter-spacing: 0.0225rem;

                        li {
                            width: 28ch;
                        }
                    }
                }
            }
        }

        .book-a-call-cta {
            margin: 0 auto;
            margin-top: 5.5rem;
            font-family: 'Helvetica Neue';
            display: flex;
            padding: 2.25rem 2.25rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            border-radius: 2.5rem;
            border: 1px solid #2A71FA;
            background: #2A71FA;
            color: #FFF;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            cursor: pointer;

        }

        .book-a-call-cta:hover {
            // border: 1px solid black;
            background: #FFF;
            color: #2A71FA;
        }

        .book-a-call-cta .arrow-icon {
            transition: opacity 0.3s ease-in-out;
        }

        .book-a-call-cta:hover .arrow-icon {
            display: none;
        }

        .book-a-call-cta .arrow-icon-hover {
            display: none;
        }

        .book-a-call-cta:hover .arrow-icon-hover {
            display: inline;
        }


        .hr-line {
            margin: 0 auto;
            margin-top: 9rem;
        }
    }
}

.feature-2-LP3-section {
    & {
        // background: #FFF !important;
        // border-radius: 2rem;
        // margin-top: 1rem;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
    }

    .feature-2-LP3 {
        & {
            padding-top: 6rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8.75rem;
        }

        .header-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 3.75rem;
                align-items: center;
            }

            .header {
                font-family: 'Helvetica Now Display';
                margin: 0;
                color: #242424;
                text-align: center;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
                /* 118.75% */
                text-transform: capitalize;
                max-width: 57.81rem;
            }

            .sub-header {
                font-family: 'Helvetica Now Display';

                margin: 0;
                color: #393939;
                text-align: center;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem;
                /* 160% */
                letter-spacing: 0.025rem;
                max-width: 49rem;
            }
        }

        .LP3F2-svgs-container {
            & {
                padding: 0 2rem 15rem;
                // padding-left: 0;
                display: flex;
                gap: 3.88rem
            }

            .left {
                display: flex;
                flex-direction: column;
                gap: 2.37rem;

                img {
                    cursor: pointer;
                }
            }

            .right {
                & {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                }

                .img-1 {
                    max-width: 100%;
                    height: auto;
                    position: relative;
                    left: 14rem;
                }

                .img-2 {}
            }
        }
    }
}

.feature-3-LP3-section {
    & {
        color: #FFF;
        position: relative;
        z-index: 2;
        background: #030303;
    }

    .dots-svg-container {
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .feature-3-LP3 {
        & {
            margin-top: 2.25rem;
            border-radius: 2.625rem;
            border: 1px solid #393939;
            position: relative;
        }


        .header-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 4.37rem;
                align-items: center;
                position: relative;
                padding: 12.75rem 2rem 11rem;

            }


            .header {
                margin: 0;
                color: #D5D5D5;
                font-family: "Helvetica Now Display";

                text-align: center;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
                /* 118.75% */
                text-transform: capitalize;
                max-width: 57.3125rem;
            }

            .sub-header {
                margin: 0;
                color: #FFF;
                text-align: center;
                font-family: "Helvetica Now Display";
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem;
                /* 160% */
                letter-spacing: 0.025rem;
                max-width: 50rem;
            }
        }

        .feature-3-cards-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 96.2rem;
            margin: 0 auto;
            gap: 2.38rem;
            padding: 0 6rem;
            padding-bottom: 11.4rem;

            .feature-3-row {
                display: flex;
                flex-wrap: wrap;
                gap: 2.38rem;
                width: 100%;
                justify-content: space-between;
            }

            .feature-3-row-top {
                padding-bottom: 8rem;
                // margin-left: 16rem;
                margin: 0 auto;

            }

            .feature-3-row-bottom {
                padding-top: 8rem;
                // margin-left: 16rem;
                margin: 0 auto;

            }

            .feature-3-divider {
                width: 100%;
                border: 0;
                border-top: 1px solid #393939;
                margin: 0;
            }

            .card {
                flex: 1 1 calc(33.333% - 2.38rem);
                /* 3 cards per row */
                display: flex;
                flex-direction: column;
                box-shadow: none;
                background: #030303;
                color: #FFF;
                cursor: auto;

                .ant-card-body {
                    padding: 0;
                }

                .card-content {
                    // padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 2.38rem;
                }

                .card-image {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 2.38rem;
                }

                .card-header {
                    max-width: fit-content;
                    margin: 0;
                    color: #FFF;
                    font-size: 2rem;
                    font-family: "Helvetica Now Display";

                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.89194rem;
                    text-transform: capitalize;
                }

                .card-subtext {
                    margin: 0;
                    color: #CECECE;
                    font-size: 1.25rem;
                    font-family: "Helvetica Now Display";

                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.67975rem;
                    letter-spacing: 0.025rem;
                    // text-transform: capitalize;
                    max-width: 22ch;
                }

                .explore-cta {
                    color: #2A71FA;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: flex;
                    gap: 0.7rem;
                    align-items: center;
                    font-family: "Helvetica Neue";
                    max-width: fit-content;
                    transition: opacity ease-in-out 0.15s;
                    cursor: pointer;

                }

                .explore-cta:hover {
                    opacity: 0.75;
                }
            }

        }

        .mobile-arrows-container {
            display: none;
        }
    }
}


.feature-4-LP3-section {
    & {
        border-radius: 2rem;
        border: 1px solid #393939;
        margin-top: 1.4rem;
        background: #272727;
        padding: 10.75rem 0;
        position: relative;
        z-index: 2;
    }

    .header-svg {
        position: absolute;
        border-radius: 2rem;
        top: 0;
        z-index: 0;
    }

    .feature-4-LP3 {
        & {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            gap: 6.25rem;
            align-items: center;
        }

        .header-container {
            & {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 5rem;
            }

            .pre-header {
                font-family: "Helvetica Neue";
                margin: 0;
                color: rgba(255, 255, 255, 0.70);
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
            }

            .header {
                margin: 0;
                color: #D5D5D5;
                text-align: center;
                font-family: "Helvetica Now Display";

                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
                /* 118.75% */
                text-transform: capitalize;
            }

            .subtext {
                margin: 0;
                color: #cecece;
                text-align: center;
                font-family: "Helvetica Now Display";
                margin-top: -1.25rem;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2.25rem;
                /* 180% */
                letter-spacing: 0.025rem;
                max-width: 55rem;
            }

        }

        .points-container {
            & {
                display: flex;
                gap: 1.19rem;
                padding: 2.62rem 2rem;
                border-radius: 1.875rem;
                background: #393939;
            }

            .point {
                display: flex;
                flex-direction: column;
                gap: 2.38rem;
                min-width: 22.5rem;
                align-items: center;


                span {
                    text-align: center;
                    color: #FFF;
                    text-align: center;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-family: "Helvetica Now Display";
                    font-weight: 500;
                    line-height: 2rem;
                    /* 133.333% */
                    letter-spacing: 0.03rem;
                    text-transform: capitalize;
                }
            }
        }

        .why-us-cta {
            background: #F2F2F2;
            border: 1px solid #010101;
            cursor: pointer;
            // border: none;
            border-radius: 2.5rem;
            display: flex;
            min-width: 13.0625rem;
            padding: 2.25rem 2.25rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            color: #0B0B0B;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            font-family: 'Helvetica Neue';
        }

        .why-us-cta .arrow-icon {
            transition: opacity 0.3s ease-in-out;
            opacity: 1;
        }

        .why-us-cta:hover .arrow-icon {
            display: none;
            opacity: 0;
        }

        .why-us-cta .arrow-icon-hover {
            transition: opacity 0.3s ease-in-out;

            display: none;
            opacity: 0;
        }

        .why-us-cta:hover .arrow-icon-hover {
            display: inline;
            opacity: 1;
        }

        .why-us-cta:hover {
            border: 1px solid #010101;
            background: #010101;
            color: #FFF;
        }
    }

}

.feature-5-LP3-section {
    & {
        border-radius: 2rem;
        background: #FFF;
        border: none;
        margin-top: 1.75rem;
        // background: #272727;
        padding: 8.5rem 6rem;
        position: relative;
        z-index: 2;
    }

    .feature-5-LP3 {
        & {
            display: flex;
            flex-direction: column;
            max-width: 80rem;
            margin: 0 auto;
        }

        .top {
            & {
                display: flex;
                justify-content: space-between;
            }

            .left {
                & {
                    margin-top: 1.4rem;
                    display: flex;
                    flex-direction: column;
                    gap: 3.31rem;
                    flex: 1 0;

                }

                .header {
                    margin: 0;
                    color: #0B0B0B;
                    font-size: 3.75rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.75rem;
                    /* 126.667% */
                    text-transform: capitalize;
                    font-family: "Helvetica Now Display";
                    max-width: 20ch;

                }

                .subtext {
                    margin: 0;

                    color: #8B8B8B;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2.1875rem;
                    /* 175% */
                    letter-spacing: 0.025rem;
                    max-width: 35rem;
                    font-family: "Helvetica Now Display";

                }
            }

            .right {
                & {
                    flex: 1 0;
                    max-width: fit-content;
                }
            }
        }

        .hr-divider {
            width: 100%;
            // height: 1px;
            // background: #8B8B8B;
            margin: 8.3rem 0 6.8rem;
            border-bottom: 1px solid rgba(135, 135, 135, 0.5);
        }

        .bottom {
            & {
                display: flex;
                flex-direction: column;
                gap: 3.5rem;
                align-items: center;
            }

            .pre-header {
                color: #969696;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
                font-family: "Helvetica Neue";

            }

            .header {
                margin: 0;
                color: #0B0B0B;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2rem;
                /* 50% */
                letter-spacing: 0.08rem;
                font-family: "Helvetica Now Display";

            }

            .subtext {
                margin: 0;
                color: #7A7A7A;
                text-align: center;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem;
                /* 160% */
                letter-spacing: 0.025rem;
                max-width: 58rem;
                font-family: "Helvetica Now Display";

            }

            .how-we-work-cta {
                font-family: 'Helvetica Neue';
                box-shadow: none;
                margin-top: 0.75rem;
                border: 0;
                display: flex;
                max-width: 18rem;
                padding: 2.25rem 2.25rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                flex-shrink: 0;
                border-radius: 2.5rem;
                background: #2A71FA;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                border: 1px solid #2A71FA;
            }

            .how-we-work-cta:hover {
                box-shadow: none;
                background: white;
                color: #2A71FA;

            }

            .how-we-work-cta .arrow-icon {
                transition: opacity 0.3s ease;
            }

            .how-we-work-cta:hover .arrow-icon {
                display: none;
            }

            .how-we-work-cta .arrow-icon-hover {
                display: none;
            }

            .how-we-work-cta:hover .arrow-icon-hover {
                display: inline;
            }
        }
    }
}

.feature-6-LP3-section {
    & {
        border-radius: 2rem;
        background: #2A71FA;
        border: none;
        margin-top: 1rem;
        padding: 10.5rem 6rem 7.56rem;
        position: relative;
        z-index: 2;
    }

    .feature-6-LP3 {
        & {
            display: flex;
            flex-direction: column;
            gap: 8rem;
        }

        .header-container {
            & {
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                gap: 3rem;
                width: 100%;
                max-width: 86.2rem;

            }

            .pre-header {
                font-family: "Helvetica Neue";

                color: rgba(255, 255, 255, 0.70);
                font-size: 1.5rem;
                font-style: normal;
                line-height: normal;
                text-transform: uppercase;
            }

            .header {
                margin: 0;
                color: #FFF;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
                /* 118.75% */
                font-family: "Helvetica Now Display";
                max-width: 20ch;

            }
        }

        .feature-6-cards-container {
            & {
                display: flex;
                flex-wrap: wrap;
                gap: 3.38rem;
                max-width: 86.2rem;
                margin: 0 auto;
                justify-content: center;
            }

            .card {
                & {
                    flex: 1 1 calc(33.333% - 2.38rem);
                    /* 3 cards per row */
                    display: flex;
                    flex-direction: column;
                    box-shadow: none;
                    /* Remove any shadow if present */
                    color: #000;
                    border-radius: 2rem;
                    border: 1px solid rgba(245, 245, 245, 0.40);
                    background: #FFF;
                }

                .ant-card-body {
                    padding: 3rem;
                }

                .card-content {
                    // padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 2.38rem;
                }

                .card-image {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 2.78rem;
                }

                .card-header {
                    margin: 0;
                    color: #0B0B0B;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2.5rem;
                    /* 125% */
                    text-transform: capitalize;
                    max-width: 15ch;
                    font-family: "Helvetica Now Display";

                }

                .card-subtext {
                    margin: 0;
                    color: #767676;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2rem;
                    /* 160% */
                    // text-transform: uppercase;
                    max-width: 30ch;
                    font-family: "Helvetica Now Display";

                }

                .explore-cta {
                    color: #2A71FA;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: flex;
                    gap: 0.7rem;
                    align-items: center;
                }
            }


        }

        .book-a-call-cta {
            border: none;
            display: flex;
            width: 14rem;
            padding: 2.25rem 2.25rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            color: #010101;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            border-radius: 2.5rem;
            background: #FFF;
        }

        .book-a-call-cta .arrow-icon {
            transition: opacity 0.3s ease;
        }

        .book-a-call-cta:hover .arrow-icon {
            display: none;
        }

        .book-a-call-cta .arrow-icon-hover {
            display: none;
        }

        .book-a-call-cta:hover .arrow-icon-hover {
            display: inline;
        }

        .book-a-call-cta:hover {
            background: #010101;
            color: #FFF;
        }
    }
}

.feature-7-LP3-section {
    & {
        position: relative;
        top: -2.5rem;
        z-index: 2;
        border-radius: 2rem;
        background: #FFF;
        border: none;
        // margin-top: 1rem;
        padding: 7rem 0rem 6rem;
        position: relative;
        overflow: hidden;
    }

    .lines-img {
        display: block;
        position: absolute;
        /* top: 0px; */
        bottom: 0px;
        overflow: hidden;
        /* max-width: 100%; */
        width: 100vw;
        height: auto;
        /* top: 0rem; */
        /* scale: 1.15; */

    }

    .lines-img-mobile {
        display: none;
    }

    .feature-7-LP3 {
        & {}

        .header-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 3rem;
                align-items: center;
            }

            .header {
                margin: 0;
                color: #0B0B0B;
                text-align: center;
                font-size: 3rem;
                font-style: normal;
                font-weight: 700;
                line-height: 3.75rem;
                /* 142.857% */
                letter-spacing: 0.0525rem;
                max-width: 25ch;
                font-family: 'Helvetica Now Display';
            }

            .schedule-call-cta {
                cursor: pointer;
                border: none;
                display: flex;
                width: 24rem;
                padding: 2.25rem 2.25rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: 2.5rem;
                background: #2A71FA;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                line-height: normal;
                text-transform: capitalize;
                font-family: 'Helvetica Neue';
                border: 1px solid #2A71FA;
            }


            .schedule-call-cta:hover {
                box-shadow: none;
                background: white;
                color: #2A71FA;

            }

            .schedule-call-cta .arrow-icon {
                transition: opacity 0.3s ease;
            }

            .schedule-call-cta:hover .arrow-icon {
                display: none;
            }

            .schedule-call-cta .arrow-icon-hover {
                display: none;
            }

            .schedule-call-cta:hover .arrow-icon-hover {
                display: inline;
            }
        }
    }
}

.feature-8-LP3-section {
    & {
        padding: 9rem 6rem 20rem;
        position: relative;
        // margin-top: 1.94rem;
        border-radius: 1.875rem;
        border-top: 1px solid #4d4d4d;
    }

    .dots-svg {
        position: absolute;
        top: 0;
        right: 0;

        z-index: 1;
        max-width: 100%;
        height: auto;

    }

    .feature-8-LP3 {
        & {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: relative;
            z-index: 2;
        }

        .header {
            margin: 0;
            color: #FFF;
            font-size: 3rem;
            font-style: normal;
            font-weight: 400;
            // line-height: 2.1875rem;
            /* 175% */
            letter-spacing: 0.0625rem;
            font-family: 'Helvetica Now Display';
            text-align: left;
        }

        .input-container {
            position: relative;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #696969;
            padding-bottom: 0.5rem;
            max-width: 52.5rem;
            /* Optional, for better alignment */
            background: #030303;

            input {
                font-family: 'Helvetica Now Display';
                font-weight: 400;

            }

        }

        .custom-input {
            flex: 1;
            // background: #000;
            background: #030303;
            border: none;
            // border-bottom: 1px solid #696969;
            /* Add bottom border */
            padding: 1.5rem 0rem;
            color: #696969;
            font-size: 3.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            outline: none;
            /* Remove the default outline */
        }

        .custom-input::placeholder {
            color: #696969;
            font-size: 3.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: #030303;
        }

        .input-arrow {
            position: absolute;
            right: 0;
            bottom: 1.5rem;

        }

    }
}


@media (max-width:1200px) {
    .feature-1-LP3-section {
        & {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .feature-1-LP3 {
            .header-container {
                .pre-header {
                    font-size: 1rem !important;
                }

                .header {
                    font-size: 2.625rem;
                    line-height: 133.33%;
                    // max-width: 80%;
                }
            }

            .feature-1-cards-container {
                & {
                    flex-direction: column;
                    padding-top: 4.25rem;
                }

                .ant-card-body {
                    .card-upper {
                        .header {
                            font-size: 2.15rem;
                            line-height: 125%;
                        }
                    }

                    .card-lower {}
                }

            }
        }
    }

    .feature-2-LP3-section {
        & {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .feature-2-LP3 {
            & {
                gap: 5rem;
            }

            .header-container {
                .header {
                    font-size: 2.625rem;
                    line-height: 133.33%;
                }

                .sub-header {
                    font-size: 1rem;
                    line-height: 150%;
                }
            }

            .LP3F2-svgs-container {
                & {
                    flex-direction: column;
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 7rem;
                }

                .left {
                    & {
                        order: 2;
                    }

                    .collapse-header-container {
                        padding: 1.25rem 1.29rem;
                        gap: 1rem;

                        .collapse-heading {
                            font-size: 1.25rem;
                            line-height: 100%;
                        }

                        .collapse-header-img {
                            scale: 0.6;
                        }
                    }

                    .ant-collapse-content-box {
                        padding: 2rem;
                        padding-left: 2.15rem;
                        padding-top: 0;

                        .collapse-subtext {
                            font-size: 1rem;
                            line-height: 129%;
                        }
                    }
                }

                .right {

                    .img-1 {
                        scale: 0.8;
                        left: 5rem;
                    }

                    .img-1,
                    .img-2 {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    .feature-3-LP3-section {
        & {
            border-radius: 2rem;
        }

        .feature-3-LP3 {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            border: none;

            .header-container {
                padding: 7rem 0rem 5.5rem;
                gap: 4rem;
                border-bottom: 1px solid #393939;

                .header {
                    font-size: 2.625rem;
                    line-height: 133.33%;
                }

            }


            .feature-3-cards-container {
                padding: 0 !important;
                margin-top: 5rem;
                flex-direction: row;
                overflow-x: scroll;
                -webkit-overflow-scrolling: touch;


                .feature-3-divider {
                    display: none;
                }

                .feature-3-row-top,
                .feature-3-row-bottom {
                    flex-direction: row;
                    flex-wrap: nowrap;
                    width: fit-content;
                    padding-bottom: 3rem;

                    .ant-card-body {
                        width: 20rem;
                    }
                }

                .feature-3-row-bottom {
                    padding-top: 0;
                }

            }

            .feature-3-cards-container::-webkit-scrollbar {
                display: none;
            }

            .mobile-arrows-container {
                display: flex;
                justify-content: center;
                gap: 1.3rem;
                padding-bottom: 4rem;

            }
        }


        .dots-svg-container {
            .f3r {
                display: none;
            }

            img {
                border-radius: 2.625rem;
                // width: 50%;
                // object-fit: cover;
            }
        }

    }

    .feature-4-LP3-section {
        overflow: hidden;
        padding: 5rem 1.5rem 7rem;

        .feature-4-LP3 {
            .header-container {
                gap: 4rem;

                .pre-header {
                    font-size: 1.25rem;
                }

                .header {
                    font-size: 2.625rem;
                    line-height: 133.33%;
                }

                .subtext {
                    font-size: 1rem;
                    line-height: 168.75%;
                }
            }

            .points-container {
                flex-direction: column;
                padding-left: 0;
                padding-right: 0;
                gap: 4rem;

                .point {
                    gap: 2.25rem;
                }
            }
        }
    }

    .feature-5-LP3-section {
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .feature-5-LP3 {
            .top {
                flex-direction: column;
                gap: 5rem;

                .left {
                    gap: 2.5rem;

                    .header {
                        font-size: 2.625rem;
                        line-height: 133.33%;
                        max-width: 95%;
                    }

                    .subtext {
                        font-size: 1rem;
                        line-height: 1.8125rem;
                        /* 181.25% */

                    }
                }
            }

            .bottom {
                text-align: center;
                gap: 2.5rem;

                .pre-header {
                    font-size: 1.25rem;
                }

                .header {
                    margin-top: 2rem;
                    font-size: 2.625rem;
                    line-height: 133.33%;
                }

                .subtext {
                    font-size: 1rem;
                    line-height: 175%;
                }

                .how-we-work-cta {
                    margin-top: 4rem;
                }
            }
        }
    }

    .feature-6-LP3-section {
        padding: 8rem 1.5rem 5.5rem;

        .feature-6-LP3 {
            .header-container {
                .pre-header {
                    font-size: 1.25rem;
                }

                .header {
                    font-size: 2.625rem;
                    line-height: 133.33%;
                }
            }

            .feature-6-cards-container {
                & {
                    overflow-x: auto;
                }

                .card {}
            }
        }
    }

    .feature-7-LP3-section {
        & {
            padding-bottom: 8rem;
        }

        .lines-img {
            display: none;
        }

        .lines-img-mobile {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
        }

    }

    .feature-8-LP3-section {
        & {
            padding: 8rem 1.5rem;
        }


        .feature-8-LP3 {
            & {
                gap: 4.5rem;
            }

            .header {
                font-size: 1rem;
                line-height: 200%;
                letter-spacing: 0.05rem;

                span {
                    text-transform: uppercase;
                    font-weight: 400 !important;
                }
            }

            .input-container {

                .custom-input,
                .custom-input::placeholder {
                    font-size: 2rem;
                }
            }
        }
    }
}