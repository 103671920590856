.feature-1-section {
    & {
        max-width: 82.75rem;
        margin: 0 auto;
        padding: 0 2rem;
        padding-top: 10rem;
        color: #000;

    }

    .headline-subheadline-container {
        font-family: "DM Sans";
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        // gap: 19.05rem;
        margin-bottom: 4.13rem;

        .headline {
            margin: 0;
            font-size: 4.375rem;
            font-style: normal;
            font-weight: 600;
            line-height: 4.375rem;
            /* 100% */
            letter-spacing: -0.0875rem;
            max-width: 12ch;
        }

        .sub-headline {
            padding-right: 3rem;
            max-width: 20ch;
            margin: 0;
            color: rgba(20, 37, 104, 0.70);
            font-size: 1.225rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.8375rem;
            /* 150% */
        }
    }

    .feature-cards-container {
        & {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            justify-content: space-between;
        }

        .feature-card {
            & {
                flex: 1 1 calc(33% - 1rem);
                /* Adjust width as needed, e.g., 33% for three cards in a row */
                display: flex;
                flex-direction: column;
                max-width: 100%;
                padding: 0.5rem;
                align-items: center;
            }

            .card-img-container {
                margin-bottom: 2rem;
                display: flex;
                justify-content: center;
            }

            .card-text-container {
                & {
                    min-height: 15.5rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                }

                .card-heading {
                    margin: 0;
                    color: #000;
                    text-align: center;
                    font-family: "DM Sans";
                    font-size: 1.575rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 1.96875rem;
                    // margin-bottom: 1.33rem;
                    /* 125% */
                }

                .card-subtext {
                    margin: 0;
                    color: rgba(20, 37, 104, 0.70);
                    font-family: "DM Sans";
                    font-size: 1.225rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.8375rem;
                    text-align: center;
                    /* 150% */
                }

            }
        }
    }
}

.feature-2-section {
    & {
        padding: 0 2rem;
        margin-top: 10rem;
        background: #000;
    }

    .feature-2 {
        & {
            max-width: 82.75rem;
            margin: 0 auto;
            color: #fff;
            padding: 7.4rem 0 6.4rem;
            display: flex;
            gap: 9.5rem;

            .header {
                & {
                    display: flex;
                    gap: 1.88rem;
                    flex-direction: column;
                    flex: 1 0;

                    .headline {
                        margin: 0;
                        color: #FFF;
                        font-family: "DM Sans";
                        font-size: 4rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        /* 100% */
                        letter-spacing: -0.0875rem;
                    }

                    .subtext {
                        margin: 0;
                        color: #FFF;
                        font-family: "DM Sans";
                        font-size: 1.2rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1.8375rem;
                        /* 150% */
                    }
                }
            }

            .icon-tabs {
                flex: 1 0;

                .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn .tab-btn {
                    padding: 1rem 2.5rem;
                    border-radius: 0.1875rem;
                    border: 1.181px solid #FFF;
                    color: black !important;
                    background: white !important;
                    transition: all 0.15s ease-in-out;
                    font-family: 'Inter';
                    font-size: 1.1805rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn .tab-btn {
                    padding: 1rem 2.5rem;
                    border-radius: 0.1875rem;
                    border: 1.181px solid #FFF;
                    color: white;
                    background: black;
                    font-family: 'Inter';
                    font-size: 1.1805rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .ant-tabs-top>.ant-tabs-nav::before {
                    border-bottom: none !important;
                }

                .ant-tabs .ant-tabs-ink-bar {
                    background: none !important;
                }
            }

        }
    }
}


.feature-3-section {
    & {
        padding: 8.2rem 2rem 0rem;
        max-width: 82.75rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 5.5rem;
    }

    .heading {
        color: #000;
        margin: 0;
        font-family: "DM Sans";
        font-size: 4.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4.375rem;
        /* 100% */
        letter-spacing: -0.0875rem;
    }

    .agent-features-1 {
        & {
            display: flex;
            gap: 2.5rem;
        }

        .box1 {
            & {
                border-radius: 0.1875rem;
                border: 5px solid #C9C9C9;
                display: flex;
                flex-direction: column;
                padding: 2.5rem;
                gap: 4.32rem;
                flex: 1 0;
            }

            .heading {
                margin: 0;
                color: #000;
                font-family: 'Inter';
                font-size: 2.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .subtext {
                margin: 0;
                color: #000;
                font-family: 'Inter';
                font-size: 1.375rem;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                /* 2.0625rem */
            }
        }

        .box2 {
            & {
                display: flex;
                flex-direction: column;
                flex: 1 0;
                border-radius: 0.1875rem;
                border: 5px solid #000;
                background: #000;
                padding: 2.5rem;
                gap: 1.25rem;
            }

            .heading {
                color: #FFF;
                font-family: 'Inter';
                font-size: 2.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                max-width: 20ch;
            }

            .subtext {
                margin: 0;
                color: #FFF;
                font-family: 'Inter';
                font-size: 1.375rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                /* 2.0625rem */
            }

            .get-started-cta {
                cursor: pointer;
                margin-top: 1rem;
                padding: 0.875rem 1.7275rem 0.875rem 1.625rem;
                border-radius: 0.1875rem;
                background: #FFF;
                color: #000;
                text-align: center;
                font-family: "DM Sans";
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5rem;
                width: 9.5rem;
                /* 150% */
            }
        }
    }

    .agent-features-2 {
        & {
            display: flex;
        }

        .left {
            & {
                background: #000;
                color: #FFF;
                padding: 6.68rem 7rem 6.12rem;
                flex: 1 0;
            }

            .header {
                font-family: "DM Sans";
                font-size: 2.8875rem;
                font-style: normal;
                font-weight: 700;
                line-height: 3.17625rem;
                /* 110% */
            }

            .subtext {
                margin-top: 0.86rem;
                color: rgba(255, 255, 255, 0.70);
                font-family: "DM Sans";
                font-size: 1.225rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.8375rem;
                /* 150% */
            }

            .learn-more-cta {
                margin-top: 3rem;
                border-radius: 0.25rem;
                background: #FFF;
                box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.06);
                display: flex;
                padding: 1.25rem 2.375rem;
                justify-content: center;
                align-items: center;
                gap: 0.60444rem;
            }
        }

        .right {
            & {
                background: #FFF;
                color: #2E2E30;
                padding: 6.68rem 5.85rem 6.12rem;
                flex: 1 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .header {
                color: #2E2E30;
                font-family: Inter;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                position: relative;
                top: -2rem;
            }

            .subtext {
                color: #2E2E30;
                font-family: Inter;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                position: relative;
                top: -2rem;
            }
        }
    }
}

.feature-4-section {
    & {
        padding: 0 2rem;
        margin-top: 10rem;
        background: #000;
    }

    .feature-4 {
        & {
            max-width: 82.75rem;
            margin: 0 auto;
            color: #fff;
            padding: 6rem 0rem;
            display: flex;
            flex-direction: column;
            gap: 3.25rem;
            align-items: center;
        }

        .header {
            margin: 0;
            color: #FFF;
            text-align: center;
            font-family: "DM Sans";
            font-size: 2.8875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 3.17625rem;
            /* 110% */
        }

        .email-form {
            display: flex;
            gap: 1rem;
            width: 100%;

            .email-input {
                // max-width: 28rem;
                display: inline-flex;
                padding: 1.28125rem 0.875rem 1.28125rem 1.375rem;
                justify-content: flex-end;
                align-items: center;
                flex: 1 0;
                border-radius: 0.1875rem;
                border: 2px solid #D1D1D3;
                background: #FFF;
            }

            .submit-button {
                cursor: pointer;
                border: none;
                display: inline-flex;
                padding: 1.28125rem 2.65063rem 1.28125rem 2.625rem;
                justify-content: center;
                align-items: center;
                color: #000;
                text-align: center;
                font-family: "DM Sans";
                font-size: 1rem;
                font-style: normal;
                font-weight: 800;
                line-height: 1.5rem;
                /* 150% */
            }
        }
    }
}

@media (max-width: 768px) {
    .feature-1-section .feature-cards-container .feature-card {
        flex: 1 1 100%;
    }
}