.services-LP2-section {
    & {
        max-width: 82.5rem;
        margin: 0 auto;
        padding: 0 2rem;
        padding-top: 10rem;
    }

    .services {
        & {
            display: flex;
            flex-direction: column;
            gap: 6.14rem;
        }

        .header {
            margin: 0;
            color: #000;
            text-align: center;
            font-family: "DM Sans";
            font-size: 4.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: 5.625rem;
            /* 128.571% */
            letter-spacing: -0.0875rem;
        }
    }
}