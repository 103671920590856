.customer-service-section {
    background: #030303;

    .customer-service-hero-section {
        & {
            background: #030303;
            padding: 2rem 0;
            // padding-top: 6rem;
        }

        // .customer-service-hero {
        //     & {
        //         position: relative;
        //         z-index: 2;
        //         display: flex;
        //         flex-direction: column;
        //         justify-content: center;
        //         align-items: center;
        //         margin-bottom: 7.5rem;

        //         // background-image: url('../../assets/imgs/customer-service-hero.png');

        //         .header-container {
        //             & {
        //                 position: absolute;
        //                 max-width: 58%;
        //                 display: flex;
        //                 flex-direction: column;
        //                 align-items: center;
        //                 gap: 3.5rem;
        //             }

        //             .pre-header {
        //                 margin: 0;
        //                 color: #9FA7AD;
        //                 font-family: "Helvetica Neue";
        //                 font-size: 1.25rem;
        //                 font-style: normal;
        //                 font-weight: 400;
        //                 line-height: normal;
        //                 letter-spacing: 0.03125rem;
        //                 text-transform: uppercase;
        //             }

        //             .header {
        //                 color: #FFF;
        //                 text-align: center;
        //                 font-family: "Helvetica Now Display";
        //                 font-size: 4.5rem;
        //                 font-style: normal;
        //                 font-weight: 700;
        //                 line-height: 4.75rem;
        //                 /* 105.556% */
        //             }

        //             .subtext {
        //                 margin: 0;
        //                 color: #FFF;
        //                 text-align: center;
        //                 font-family: "Helvetica Now Display";
        //                 font-size: 1.25rem;
        //                 font-style: normal;
        //                 font-weight: 400;
        //                 line-height: 1.875rem;
        //                 /* 150% */
        //                 letter-spacing: 0.025rem;
        //             }

        //             .get-started-cta {
        //                 font-family: "Helvetica Now Display";
        //                 margin-top: 0.75rem;
        //                 max-width: 16rem;
        //                 border: none;
        //                 // margin-top: 2.5rem;
        //                 display: flex;
        //                 padding: 1.625rem 1.625rem;
        //                 justify-content: center;
        //                 align-items: center;
        //                 gap: 0.625rem;
        //                 border-radius: 2.5rem;
        //                 background: #2A71FA;
        //                 color: #FFF;
        //                 font-size: 1.25rem;
        //                 font-style: normal;
        //                 font-weight: 500;
        //                 line-height: normal;
        //                 text-transform: capitalize;
        //             }

        //             .get-started-cta:hover {
        //                 background: #dfdfdf;
        //                 color: #2A71FA;
        //             }
        //         }
        //     }
        // }    
        .customer-service-hero {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 1.5rem 7.5rem;
            // margin-bottom: 7.5rem;
            height: auto; // Ensure height is determined by content

            .background-img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url('../../assets/imgs/customer-service-hero.png');
                background-size: cover; // Ensures the background image does not get cropped
                background-position: center;
                z-index: -1; // Places the background behind the content
                border-radius: 2rem;
            }

            .header-container {
                max-width: 58%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 3.5rem;
                position: relative;
                z-index: 1; // Ensures the content is above the background image
                padding: 7.5rem 1.5rem;
            }

            .pre-header {
                margin: 0;
                color: #9fa7ad;
                font-family: "Helvetica Neue";
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.03125rem;
                text-transform: uppercase;
            }

            .header {
                color: #fff;
                text-align: center;
                font-family: "Helvetica Now Display";
                font-size: 4.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
            }

            .subtext {
                margin: 0;
                color: #fff;
                text-align: center;
                font-family: "Helvetica Now Display";
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.875rem;
                letter-spacing: 0.025rem;
            }

            .get-started-cta {
                font-family: "Helvetica Now Display";
                margin-top: 0.75rem;
                max-width: 16rem;
                border: none;
                display: flex;
                padding: 1.625rem 1.625rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                border-radius: 2.5rem;
                background: #2a71fa;
                color: #fff;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
            }

            .get-started-cta:hover {
                background: #dfdfdf;
                color: #2a71fa;
            }
        }

        .carousel-header {
            margin: 7.4rem auto 4.75rem;
            // margin-top: 7.4rem;

            max-width: fit-content;
            color: #BDBDBD;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.03125rem;
            text-transform: capitalize;
        }

        .how-it-works-gridImg-container {
            margin-top: 1rem;
            display: flex;
            justify-content: center;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    .cs-advantage-section {
        & {
            border-radius: 2rem;
            background: #FFF;
            // fill: #FFF;
            filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.15)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.30));
            position: relative;
            z-index: 2;
        }

        .cs-advantage {
            & {
                display: flex;
                flex-direction: column;
                gap: 10rem;
                align-items: center;
                padding: 10rem 1.5rem 11rem;
            }

            .header-container {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 4rem;
                    align-items: center;

                }

                .img-l {
                    position: absolute;
                    top: 0;
                    left: 0;

                }

                .img-r {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                .pre-header {
                    color: #393939;
                    font-family: "Helvetica Neue";
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.03125rem;
                    text-transform: uppercase;
                }

                .header {
                    color: #1B1B1B;
                    text-align: center;
                    font-family: "Helvetica Now Display";
                    font-size: 3.5625rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.4375rem;
                    /* 124.561% */
                }

                .subtext {
                    color: #828997;
                    text-align: center;
                    font-family: "Helvetica Now Display";
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2rem;
                    /* 160% */
                    max-width: 62%;
                }
            }

            .cs-advantage-cards-container {
                & {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2rem;
                    max-width: 80rem;
                    margin: 0 auto;
                    justify-content: center;
                }

                .card {
                    & {
                        flex: 1 1 calc(33.333% - 2.38rem);
                        /* 3 cards per row */
                        display: flex;
                        flex-direction: column;
                        box-shadow: none;
                        /* Remove any shadow if present */
                        color: #000;
                        border-radius: 2rem;
                        // border: 1px solid rgba(245, 245, 245, 0.40);
                        border: 1px solid #C5C5C5 !important;


                        background: #FFF;
                    }

                    .ant-card-body {
                        padding: 3rem 2rem 1.5rem;
                    }

                    .card-content {
                        // padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        gap: 2.38rem;
                    }

                    .card-image {
                        width: 50px;
                        height: 50px;
                        margin-bottom: 2.78rem;
                    }

                    .card-header {
                        margin: 0;
                        color: #0B0B0B;
                        font-size: 2rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 2.5rem;
                        /* 125% */
                        text-transform: capitalize;
                        max-width: 15ch;
                        font-family: "Helvetica Now Display";

                    }

                    .card-subtext {
                        margin: 0;
                        color: #767676;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2rem;
                        /* 160% */
                        // text-transform: uppercase;
                        max-width: 30ch;
                        font-family: "Helvetica Now Display";

                    }
                }


            }
        }
    }

    .how-cso-works-section {
        & {
            background: #030303;
        }

        .dots-svg-container {
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: 100%;
            z-index: 1;
        }

        .how-cso-works {
            & {
                padding: 12rem 1.5rem;
                display: flex;
                flex-direction: column;
                gap: 10rem;
                position: relative;
                z-index: 2;
                align-items: center;
            }

            .header {
                color: #FFF;
                text-align: center;
                font-family: "Helvetica Now Display";
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.75rem;
                /* 118.75% */
                text-transform: capitalize;
                max-width: 60%;
            }

            .how-cso-works-cards-container {
                & {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 2rem;
                    max-width: 80rem;
                    margin: 0 auto;
                    justify-content: center;
                }

                .card {
                    & {
                        flex: 1 1 calc(33.333% - 2.38rem);
                        /* 3 cards per row */
                        display: flex;
                        flex-direction: column;
                        box-shadow: none;
                        /* Remove any shadow if present */
                        border: 1px solid #404040 !important;
                        color: #FFF;
                        border-radius: 2rem;
                        background: #030303;
                    }

                    .ant-card-body {
                        padding: 3rem 2rem 1.5rem;
                    }

                    .card-content {
                        // padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        gap: 2.38rem;
                    }

                    .card-image {
                        width: 50px;
                        height: 50px;
                        margin-bottom: 2.78rem;
                    }

                    .card-header {
                        margin: 0;
                        color: #FFF;
                        font-size: 2rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 2.5rem;
                        /* 125% */
                        text-transform: capitalize;
                        max-width: 15ch;
                        font-family: "Helvetica Now Display";

                    }

                    .card-subtext {
                        margin: 0;
                        color: #A9A9A9;
                        font-size: 1.25rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2rem;
                        /* 160% */
                        // text-transform: uppercase;
                        max-width: 30ch;
                        font-family: "Helvetica Now Display";

                    }
                }


            }
        }
    }

    .faqs-section {
        & {
            margin-top: 1.5rem;
            padding: 7.8rem 6rem 12.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2rem;
            border: 1px solid #393939;
            background: #2A71FA;
            position: relative;
            z-index: 3;
        }

        .faqs-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 4rem;
                align-items: center;
            }

            .header-container {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 4.2rem;
                    align-items: center;
                }

                .pre-header {
                    color: rgba(255, 255, 255, 0.70);
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;
                    font-family: 'Helvetica Neue Light';
                }

                .header {
                    margin: 0;
                    color: #FFF;
                    font-size: 4rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.75rem;
                    /* 118.75% */
                    text-transform: capitalize;
                    font-family: 'Helvetica Now Display';
                }
            }

            .faqs-collapse-container {
                & {
                    padding-top: 4rem;
                }
            }
        }
    }

    .scale-teams-section {
        & {
            background: #030303;
            z-index: 2;
            position: relative;
        }

        .scale-teams {
            & {
                padding: 12rem 1.5rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8rem;
            }

            .header-container {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 4rem;
                    align-items: center;
                    max-width: fit-content;
                }

                .header {
                    color: #FFF;
                    font-family: "Helvetica Now Display";
                    font-size: 4rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.75rem;
                    /* 118.75% */
                    text-transform: capitalize;
                }

                .subtext {
                    color: #CECECE;
                    text-align: center;
                    font-family: "Helvetica Now Display";
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2rem;
                    /* 160% */
                    letter-spacing: 0.025rem;
                }
            }

            .agent-val-grid {
                width: 90%;

                .agent-val-cell {}

                .agent-val-cell:hover {
                    background: #030303 !important;
                    color: white !important;
                }
            }

            .schedule-call-container {
                display: flex;
                gap: 2rem;
                align-items: center;
                justify-content: space-between;
                padding: 1.5rem 0.75rem;
                padding-left: 3.25rem;
                max-width: fit-content;

                .schedule-text {
                    color: #FFF;
                    font-family: "Helvetica Now Display";
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2.1875rem;
                    /* 145.833% */
                    text-transform: capitalize;
                }

                .schedule-call-cta {
                    max-width: fit-content;
                    cursor: pointer;
                    border: none;
                    display: flex;
                    width: 24rem;
                    // padding: 2.25rem 2.25rem;
                    padding: 0;
                    justify-content: center;
                    align-items: center;
                    gap: 0.625rem;
                    border-radius: 2.5rem;
                    background: none;
                    color: #2A71FA;
                    font-size: 1.25rem;
                    font-style: normal;
                    line-height: normal;
                    text-transform: capitalize;
                    font-family: 'Helvetica Neue';
                    border: none;
                }


                .schedule-call-cta:hover {
                    box-shadow: none;
                    // background: white;
                    color: #fff;

                }

                .schedule-call-cta .arrow-icon {
                    transition: opacity 0.3s ease;
                    opacity: 1;
                    position: relative;
                    left: -2.15rem;
                }

                .schedule-call-cta:hover .arrow-icon {
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }

                .schedule-call-cta .arrow-icon-hover {
                    transition: opacity 0.3s ease;
                    opacity: 0;

                }

                .schedule-call-cta:hover .arrow-icon-hover {
                    opacity: 1;
                    transition: opacity 0.3s ease;
                }
            }
        }


    }

}

@media (max-width:1200px) {
    .customer-service-section {
        .customer-service-hero-section {
            .customer-service-hero {
                .header-container {
                    & {
                        padding-left: 0;
                        padding-right: 0;
                        gap: 2rem;
                        max-width: 90%;
                    }

                    .pre-header {
                        font-size: 1rem;
                        line-height: 150%;
                    }

                    .header {
                        font-size: 2.5rem;
                        line-height: 133.33%;
                    }

                    .subtext {
                        font-size: 1rem;
                        // max-width: 42ch;
                        max-width: 90%;

                    }
                }
            }
        }

        .cs-advantage-section {
            .cs-advantage {
                padding-top: 6rem;
                gap: 5rem;

                .header-container {
                    gap: 3.5rem;

                    .pre-header {
                        font-size: 1rem;
                        line-height: 150%;
                    }

                    .header {
                        font-size: 2.25rem;
                        line-height: 133.33%;
                    }

                    .subtext {
                        font-size: 1rem;
                        line-height: 150%;
                        max-width: 80%;
                        padding-bottom: 1rem;
                    }

                    .img-l,
                    .img-r {
                        z-index: 1;
                        display: none;
                    }
                }

                .cs-advantage-cards-container {
                    .card {
                        .ant-card-body {
                            padding-bottom: 2.25rem;
                        }

                        .card-content {
                            gap: 2rem;
                        }

                        .card-header {
                            font-size: 2rem;
                            line-height: 133.33%;
                        }

                        .card-subtext {
                            font-size: 1rem;
                            line-height: 150%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .how-cso-works-section {
            .dots-svg-container {
                display: none;
            }

            .how-cso-works {
                padding-top: 10rem;
                gap: 5rem;

                .header {
                    font-size: 2.5rem;
                    line-height: 120%;
                    max-width: 100%;
                }
            }

            .how-cso-works-cards-container {
                .card {
                    .ant-card-body {
                        padding-bottom: 2.25rem !important;
                    }

                    .card-content {
                        gap: 1.75rem !important;

                        .card-image {
                            margin-bottom: 1.5rem;
                        }

                        .card-header {
                            font-size: 2rem;
                            line-height: 133.33%;
                        }

                        .card-subtext {
                            font-size: 1rem;
                            line-height: 150%;
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .faqs-section {
            & {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                padding-bottom: 9rem;
            }

            .faqs-container {
                & {}

                .header-container {
                    & {
                        align-items: flex-start;
                        gap: 3rem;

                    }

                    .pre-header {
                        font-size: 1.25rem;
                    }

                    .header {
                        font-size: 2.625rem;
                        line-height: 133.33%;
                    }
                }

                .faqs-collapse-container {
                    padding-top: 0 !important;

                    .ant-collapse {
                        & {
                            padding: 4rem 1.5rem;

                        }


                        .faq-collapse-panel {
                            .ant-collapse-header {
                                padding: 3rem 0rem;

                                .ant-collapse-header-text {
                                    font-size: 1.25rem;
                                    line-height: 150%;
                                }
                            }

                            .ant-collapse-content-box {
                                padding-left: 0rem;
                                padding-right: 0rem;
                            }
                        }

                    }
                }
            }
        }

        .scale-teams-section {
            .scale-teams {
                padding: 7.5rem 1.5rem;
                gap: 4.75rem;

                .header-container {
                    gap: 3.6rem;

                    .header {
                        font-size: 2.5rem;
                        line-height: 125%;
                        text-align: center;
                    }

                    .subtext {
                        font-size: 1rem;
                        line-height: 150%;
                    }
                }

                .agent-val-cell {
                    padding: 2.75rem !important;
                }
            }

            .schedule-call-container {
                flex-direction: column;
                align-items: center;
                padding: 1.5rem !important;

                .schedule-text {
                    font-size: 1.5rem;
                    text-align: center;
                }

                .schedule-call-cta {
                    margin-left: 2.25rem;
                }
            }
        }
    }
}