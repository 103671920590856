.back-office-section {
    & {
        background: #fff;
        padding: 7.2rem 2rem 15rem;
    }

    .back-office {
        & {
            max-width: 82.5rem;
            margin: 0 auto;
        }

        .hero-section {
            & {
                margin-left: -3.175rem;
                display: flex;
                justify-content: space-between;
                // gap: 6.85rem;
            }

            .hero-headline {
                & {
                    position: relative;
                    color: #000;
                    font-family: "DM Sans";
                    font-size: 7.4375rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 7.5rem;
                    /* 100.84% */
                    letter-spacing: -0.22313rem;
                    max-width: 9ch;
                }

                .line-1 {
                    // scale: 0.9;
                    position: absolute;
                    // top: 14.5rem;
                    // left: 17.5rem;
                    // left: 24rem;

                    bottom: 7.75rem;
                }

            }

            .hero-subtext-container {
                & {
                    // padding-top: 2.5rem;
                    display: flex;
                    flex-direction: column;
                    gap: 5rem;
                    min-width: fit-content;
                }

                .hero-subtext {
                    margin: 0;
                    color: rgba(0, 0, 0, 0.70);
                    font-family: "DM Sans";
                    font-size: 1.225rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.8375rem;
                    /* 150% */
                    max-width: 25ch;
                }

                .hero-cta-container {
                    & {
                        display: flex;
                        gap: 1.25rem;
                        align-items: center;
                    }

                    .get-started-cta {
                        cursor: pointer;
                        border-radius: 0.1875rem;
                        // max-width: 13rem;
                        min-width: fit-content;
                        border: none;
                        // margin-top: 3rem;
                        background: #000;
                        display: flex;
                        padding: 1.25rem 2.81rem;
                        justify-content: center;
                        align-items: center;
                        gap: 0.6rem;
                        color: #FFF;
                        text-align: center;
                        font-family: "DM Sans";
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.5rem;
                        /* 150% */
                    }

                    .learn-more-2-cta {
                        min-width: fit-content;
                        // max-width: 9.5rem;
                        border: none;
                        color: #0A1953;
                        text-align: center;
                        font-family: "DM Sans";
                        font-size: 1rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1.5rem;
                        /* 150% */
                        background: rgba(255, 255, 255, 0.00);
                        box-shadow: 0px -2px 0px 0px rgba(0, 171, 214, 0.20) inset;
                    }
                }
            }
        }
    }
}