.header-LP3-container {
    & {
        padding: 1.5rem 3rem;
        padding-bottom: 2.25rem;
        border-radius: 1.25rem;
        // border-top-left-radius: 0;
        // border-top-right-radius: 0;
        border: 1px solid #393939;
        background: #141414;
        position: relative;
        z-index: 100;
        font-family: 'Helvetica Neue';
    }

    .header-LP3 {
        .mobile-hamburger-container {
            display: none;
        }

        & {
            // max-width: 84rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


        .quote-cta,
        .quote-cta-header-hire-support {
            border: none;
            display: flex;
            padding: 1.5rem 1.625rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            border-radius: 2.5rem;
            background: #2A71FA;
            color: #FFF;
            font-family: "Helvetica Neue";
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: none;
        }
    }



    .desktop-navbar-container {
        & {
            justify-content: center;
            display: flex;
            gap: 3.87rem;
            // position: relative;
            // top: -34px;
            margin-top: -34px;
        }

        .navbar-item {
            color: #D5D5D5;
            font-family: "Helvetica Neue Light";
            font-size: 1.125rem;
            font-style: normal;
            // font-weight: 400;
            line-height: normal;
            cursor: pointer;
            transition: all ease-in-out 0.25s;
        }

        .navbar-item:hover {
            color: #2A71FA;
        }

    }

}

.hamburger-drawer {


    .ant-drawer-body .mobile-navbar-container {
        & {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }
    }

    font-family: "Helvetica Neue Light";
    font-size: 1.125rem;


}

@media (max-width: 1200px) {
    .header-LP3-container {
        padding-bottom: 1.5rem;

        .header-LP3 .quote-cta {
            display: none;
        }

        .desktop-navbar-container {
            display: none;
        }

        .header-LP3 .mobile-hamburger-container {
            display: block;
        }
    }
}

@media (max-width: 768px) {
    .header-LP3-container {
        padding: 1.75rem 1.5rem !important;


        .header-LP3 {
            .quote-cta {
                color: #FFF;
                font-family: "Helvetica Neue";
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}