//General Sans -> DM Sans
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./assets/fonts/HelveticaNeue/HelveticaNeueRoman.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Light';
  src: url('./assets/fonts/HelveticaNeue/HelveticaNeueLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue Medium';
  src: url('./assets/fonts/HelveticaNeue/HelveticaNeueMedium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src: local('Helvetica Now Display Bold'), local('Helvetica-Now-Display-Bold'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.woff') format('woff'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src: local('Helvetica Now Display Medium'), local('Helvetica-Now-Display-Medium'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.woff') format('woff'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Now Display';
  src: local('Helvetica Now Display Regular'), local('Helvetica-Now-Display-Regular'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.woff2') format('woff2'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.woff') format('woff'),
    url('./assets/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'DM Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'General Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F5F5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}