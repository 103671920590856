.modal-container {
    & {
        border-radius: 2rem;
        background: #fff;
        display: flex;
    }

    .modal123-left {
        & {
            background: #2A71FA;
            color: #fff;
            width: 45%;
            padding: 2rem 3rem;
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
            display: flex;
            flex-direction: column;
            gap: 2.88rem;
        }

        .l-1 {
            & {
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
            }

            .header {
                margin: 0;
                color: #FFF;
                font-size: 1.75rem;
                font-style: normal;
                font-weight: 700;
                line-height: 3.79375rem;
                /* 216.786% */
                // text-transform: capitalize;
                font-family: "Helvetica Now Display";

            }

            .l1-list-container {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    border-radius: 1.0625rem;
                    background: #FFF;
                    padding: 2rem 1.65rem;

                }

                .list-item {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    color: #545454;
                    font-family: 'Helvetica Now Display';
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.625rem;
                    /* 144.444% */
                    letter-spacing: 0.0225rem;
                    text-transform: capitalize;
                    max-width: 28ch;
                }
            }
        }

        .l-2 {
            & {
                display: flex;
                flex-direction: column;
                gap: 2rem;

            }

            .l2-text {
                color: #FFF;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem;
                /* 160% */
                // text-transform: capitalize;
                max-width: 22rem;
                font-family: "Helvetica Now Display";

            }

            .l2-grid {
                margin-left: -0.8rem;

                .grid-row {
                    .grid-col {
                        display: flex;
                        flex-direction: column;
                        gap: 1.75rem;
                        padding: 1.75rem;
                        margin: 0.4rem;
                        border-radius: 1.875rem;

                        .metric-val {
                            margin: 0;
                            font-family: "Helvetica Now Display";
                            font-size: 2.625rem;
                            font-style: normal;
                            font-weight: 700;
                            letter-spacing: 0.0525rem;
                            text-transform: capitalize;
                            line-height: 100%;
                        }

                        .metric-text {
                            margin: 0;
                            font-family: "Helvetica Now Display";
                            font-size: 1.25rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.5625rem;
                            letter-spacing: 0.025rem;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }

    .modal1-right {
        & {
            color: #0b0b0b;
            width: 58%;
            padding: 4rem 6rem 0rem;
            padding-top: 7.5rem;
        }

        .page-no {
            & {
                color: #ACACAC;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                font-family: "Helvetica Now Display";
                padding-bottom: 2rem;
                // line-height: 4.75rem;
                // text-transform: capitalize;
            }

            .curr-page {
                color: #313131;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                // line-height: 4.75rem;
                font-family: "Helvetica Now Display";

                /* 422.222% */
                text-transform: capitalize;
            }
        }

        .modal1-r-heading {
            margin: 0;
            padding-top: 1rem;
            color: #0B0B0B;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.875rem;
            /* 127.778% */
            text-transform: capitalize;
            max-width: 20ch;
            font-family: "Helvetica Now Display";

        }

        .error-text {
            position: relative;
            top: 0.75rem;
            // margin-top: 0.75rem;
            padding: 0.5rem;
            color: #DB0011;
        }

        .agent-val-cell:hover {
            background: #030303 !important;
            color: white !important;
        }

        .modal1-r-subtext {
            margin: 0;
            margin-top: 3.5rem;
            color: #3E3E3E;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.9375rem;
            max-width: 40ch;
            font-family: "Helvetica Now Display";


        }

        .cta-container {
            & {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .begin-cta {
                margin-top: 6rem;
                width: 100%;
                border-radius: 2.5rem;
                background: #2A71FA;
                display: flex;
                padding: 2.25rem 1.625rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                font-family: 'Helvetica Neue';
            }

            .begin-cta:hover {
                box-shadow: none;
                background: white;
                color: #2A71FA;
                border: 1px solid #2A71FA;

            }

            .begin-cta .arrow-icon {
                transition: opacity 0.3s ease;
            }

            .begin-cta:hover .arrow-icon {
                display: none;
            }

            .begin-cta .arrow-icon-hover {
                display: none;
            }

            .begin-cta:hover .arrow-icon-hover {
                display: inline;
            }

            .back-cta {
                padding: 0;
                border: none;
                margin: 0 auto;
                margin-top: 2.94rem;
                color: #282828;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                font-family: 'Helvetica Neue';

            }
        }

    }

    .modal2-right {
        & {
            color: #0b0b0b;
            width: 58%;
            padding: 4rem 6rem 0rem;
            padding-top: 7.5rem;

        }

        .page-no {
            & {
                color: #ACACAC;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                font-family: "Helvetica Now Display";
                padding-bottom: 2rem;
                // line-height: 4.75rem;
                // text-transform: capitalize;
            }

            .curr-page {
                color: #313131;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                // line-height: 4.75rem;
                font-family: "Helvetica Now Display";

                /* 422.222% */
                text-transform: capitalize;
            }
        }

        .modal2-r-heading {
            margin: 0;
            padding-top: 1rem;
            color: #0B0B0B;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.875rem;
            /* 127.778% */
            text-transform: capitalize;
            max-width: 20ch;
            font-family: 'Helvetica Now Display';

        }

        .modal2-r-subtext {
            margin: 0;
            margin-top: 3.44rem;
            color: #3E3E3E;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.9375rem;
            max-width: 40ch;
            font-family: 'Helvetica Now Display';


        }

        .error-text {
            position: relative;
            top: 0.5rem;
            // margin-top: 0.75rem;
            padding: 0 0.5rem;
            // padding-top: 0;
            color: #DB0011;
        }


        .company-val-cell:hover {
            background: #030303 !important;
            color: white !important;
        }

        .modal2-dropdown-container {
            margin-top: 3.3rem;

            .dropdown-heading {
                color: #3E3E3E;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                font-family: 'Helvetica Now Display';
            }

            .select-container {
                width: 100%;
                color: #9A9A9A;
                height: 3.5rem;

                .ant-select-selector {
                    // padding: 1rem;
                    border-radius: 0.8125rem;
                    border: 1px solid #CACACA;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    font-family: 'Helvetica Now Display';

                    text-transform: capitalize;

                    .ant-select-selection-placeholder,
                    .ant-select-selection-item {
                        padding-inline-start: 0.75rem;
                    }

                }
            }
        }

        .cta-container {
            & {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .begin-cta {
                margin-top: 4rem;
                width: 100%;
                border-radius: 2.5rem;
                background: #2A71FA;
                display: flex;
                padding: 2.25rem 1.625rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .begin-cta:hover {
                box-shadow: none;
                background: white;
                color: #2A71FA;
                border: 1px solid #2A71FA;

            }

            .begin-cta .arrow-icon {
                transition: opacity 0.3s ease;
            }

            .begin-cta:hover .arrow-icon {
                display: none;
            }

            .begin-cta .arrow-icon-hover {
                display: none;
            }

            .begin-cta:hover .arrow-icon-hover {
                display: inline;
            }

            .back-cta {
                padding: 0;
                border: none;
                margin: 0 auto;
                margin-top: 2.5rem;
                color: #282828;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

            }
        }
    }

    .modal3-right {
        & {
            color: #0b0b0b;
            width: 58%;
            padding: 4rem 6rem 0rem;
            padding-top: 7.5rem;

        }

        .page-no {
            & {
                color: #ACACAC;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                font-family: "Helvetica Now Display";
                padding-bottom: 2rem;
                // line-height: 4.75rem;
                // text-transform: capitalize;
            }

            .curr-page {
                color: #313131;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                // line-height: 4.75rem;
                font-family: "Helvetica Now Display";

                /* 422.222% */
                text-transform: capitalize;
            }
        }

        .modal3-r-heading {
            margin: 0;
            padding-top: 1rem;
            color: #0B0B0B;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.875rem;
            /* 127.778% */
            text-transform: capitalize;
            max-width: 20ch;
            font-family: "Helvetica Now Display";

        }

        .error-text {
            position: relative;
            top: -0.55rem;
            // margin-top: 0.75rem;
            padding: 0 0.5rem;
            color: #DB0011;
        }

        .modal3-input-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 1.85rem;
                margin-top: 2.75rem;
            }

            .ip1,
            .ip2,
            .ip3 {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                }

                .input-header {
                    margin: 0;
                    color: #3E3E3E;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 700;
                    font-family: 'Helvetica Now Display';
                    // line-height: 4.75rem;
                }

                .ant-input {
                    width: 100%;
                    color: #9A9A9A;
                    font-family: 'Helvetica Now Display';

                    padding: 1rem;
                    border-radius: 0.8125rem;
                    border: 1px solid #CACACA;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 500;
                }
            }

        }

        .cta-container {
            & {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .begin-cta {
                margin-top: 2.85rem;
                width: 100%;
                border-radius: 2.5rem;
                background: #2A71FA;
                display: flex;
                padding: 2.25rem 1.625rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .begin-cta:hover {
                box-shadow: none;
                background: white;
                color: #2A71FA;
                border: 1px solid #2A71FA;

            }

            .begin-cta .arrow-icon {
                transition: opacity 0.3s ease;
            }

            .begin-cta:hover .arrow-icon {
                display: none;
            }

            .begin-cta .arrow-icon-hover {
                display: none;
            }

            .begin-cta:hover .arrow-icon-hover {
                display: inline;
            }

            .back-cta {
                padding: 0;
                border: none;
                margin: 0 auto;
                margin-top: 1.75rem;
                color: #282828;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

            }
        }
    }

    .modal4-left {
        & {
            background: #2A71FA;
            color: #fff;
            width: 45%;
            padding: 3rem;
            padding-bottom: 1rem;
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
            display: flex;
            flex-direction: column;
            gap: 2.75rem;
            // justify-content: space-around;
        }

        .l-1 {
            & {
                display: flex;
                gap: 2.25rem;
                flex-direction: column;
            }

            .header {
                margin: 0;
                color: #FFF;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2.29375rem;
                /* 152.917% */
                text-transform: capitalize;
                max-width: 22ch;
                font-family: "Helvetica Now Display";

            }

            .l1-grid-container {
                & {
                    border-radius: 1rem;
                    padding: 1rem;
                    background: #FFF;
                }

                .grid-row {
                    .grid-col {
                        .grid-cell {
                            & {
                                display: flex;
                                gap: 1rem;
                                align-items: center;
                            }

                            .text {
                                color: #565656;

                                font-family: "Helvetica Now Display";
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1.625rem;
                                /* 185.714% */
                                letter-spacing: 0.0175rem;
                            }
                        }

                    }
                }
            }
        }

        .l-2 {
            & {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
            }

            .header {
                margin: 0;
                color: #FFF;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 600;
                line-height: 2.29375rem;
                /* 152.917% */
                text-transform: capitalize;
                max-width: 24ch;
                font-family: "Helvetica Now Display";

            }

            .subtext {
                margin: 0;
                color: #FFF;
                font-size: 1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.625rem;
                letter-spacing: 0.02rem;
                font-family: "Helvetica Now Display";

            }

            .l2-list-container {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    border-radius: 0.875rem;
                    background: #FFF;
                    padding: 1.75rem 1.625rem;

                }

                .list-item {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    color: #565656;
                    font-family: "Helvetica Now Display";
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.625rem;
                    /* 144.444% */
                    letter-spacing: 0.0225rem;
                }
            }
        }
    }

    .modal4-right {
        & {
            color: #0b0b0b;
            width: 58%;
            padding: 4rem 6rem 3rem;
            padding-top: 7.5rem;

        }

        .page-no {
            & {
                color: #ACACAC;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                font-family: "Helvetica Now Display";
                padding-bottom: 2rem;
                // line-height: 4.75rem;
                // text-transform: capitalize;
            }

            .curr-page {
                color: #313131;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                // line-height: 4.75rem;
                font-family: "Helvetica Now Display";

                /* 422.222% */
                text-transform: capitalize;
            }
        }

        .modal4-r-heading {
            margin: 0;
            color: #0B0B0B;
            font-family: "Helvetica Now Display";
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 3rem;
            /* 133.333% */
            text-transform: capitalize;
            max-width: 18ch;
        }

        .error-text {
            margin: 0;
            color: #DB0011;
            position: relative;
            margin-top: -1rem;

        }

        .modal4-input-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 1.75rem;
                margin-top: 2.331rem;
            }

            .ip1 {
                width: 45%;
            }

            .ip1,
            .ip2,
            .ip3 {
                & {
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                }

                .input-header {
                    margin: 0;
                    color: #3E3E3E;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 700;
                    font-family: 'Helvetica Now Display';
                    // line-height: 4.75rem;
                }

                .ant-input {
                    width: 100%;
                    color: #9A9A9A;
                    font-family: 'Helvetica Now Display';

                    padding: 1rem;
                    border-radius: 0.8125rem;
                    border: 1px solid #CACACA;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 500;
                }
            }

        }

        .disclaimer-text {
            margin: 0;
            margin-top: 1.5rem;
            color: #7E8F9A;

            font-family: "Helvetica Now Display";
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.625rem;
            /* 162.5% */
            letter-spacing: 0.02rem;
            // max-width: 50ch;
            display: flex;
            gap: 0.75rem;
            align-items: flex-start;

            .checkbox {
                margin-top: 0.15rem;
            }
        }

        .cta-container {
            & {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .begin-cta {
                margin-top: 1.65rem;
                width: 100%;
                border-radius: 2.5rem;
                background: #2A71FA;
                display: flex;
                padding: 2.25rem 1.625rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;
                color: #FFF;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .begin-cta:hover {
                box-shadow: none;
                background: white;
                color: #2A71FA;
                border: 1px solid #2A71FA;

            }

            .begin-cta .arrow-icon {
                transition: opacity 0.3s ease;
            }

            .begin-cta:hover .arrow-icon {
                display: none;
            }

            .begin-cta .arrow-icon-hover {
                display: none;
            }

            .begin-cta:hover .arrow-icon-hover {
                display: inline;
            }

            .back-cta {
                padding: 0;
                border: none;
                margin: 0 auto;
                margin-top: 2rem;
                color: #282828;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

            }
        }


    }

    .modal5-left {
        & {
            background: #2A71FA;
            color: #fff;
            width: 45%;
            padding: 4.75rem 3rem 3rem;
            border-top-left-radius: 2rem;
            border-bottom-left-radius: 2rem;
            display: flex;
            flex-direction: column;
            gap: 5.05rem;
        }

        .l-1 {
            & {
                padding: 1.75rem 1.5rem;
                border-radius: 1.125rem;
                background: white;
            }

            .l1-text {
                margin: 0;
                color: #0C0C0C;
                font-family: "Helvetica Now Display";
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.625rem;
                /* 152.941% */
                letter-spacing: 0.02125rem;
                max-width: 33ch;
            }
        }

        .l-2 {
            & {
                display: flex;
                flex-direction: column;
                gap: 2.2rem;

            }

            .l2-text {
                color: #FFF;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: 2rem;
                /* 160% */
                max-width: 24rem;
                font-family: 'Helvetica Now Display';
            }

            .l2-grid {
                margin-left: -0.8rem;

                .grid-row {
                    .grid-col {
                        display: flex;
                        flex-direction: column;
                        gap: 1.75rem;
                        padding: 1.75rem;
                        margin: 0.4rem;
                        border-radius: 1.875rem;

                        .metric-val {
                            margin: 0;
                            font-family: "Helvetica Now Display";
                            font-size: 2.625rem;
                            font-style: normal;
                            font-weight: 700;
                            letter-spacing: 0.0525rem;
                            text-transform: capitalize;
                            line-height: 100%;
                        }

                        .metric-text {
                            margin: 0;
                            font-family: "Helvetica Now Display";
                            font-size: 1.25rem;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 1.5625rem;
                            letter-spacing: 0.025rem;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }

    .modal5-right {
        & {
            color: #0b0b0b;
            width: 58%;
            padding: 5rem 6rem 0rem;
            display: flex;
            flex-direction: column;
            gap: 2.75rem;
        }

        .header {
            margin: 0;
            color: #0B0B0B;
            font-size: 2.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 3rem;
            /* 133.333% */
            text-transform: capitalize;
            max-width: 21ch;
            font-family: 'Helvetica Now Display';
        }



        .whats-next-container {
            & {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }

            .header {
                margin: 0;
                color: #28292D;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.875rem;
                /* 150% */
                letter-spacing: 0.025rem;
                text-transform: capitalize;
                font-family: 'Helvetica Now Display';
            }

            .list-container {
                & {
                    padding-left: 1.3rem;
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                }

                li {
                    max-width: 40ch;
                    color: #778092;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 2.25rem;
                    letter-spacing: 0.025rem;
                    // text-transform: capitalize;
                    font-family: 'Helvetica Now Display';
                }
            }
        }

    }
}


@media (max-width: 1250px) {
    .modal-container {
        flex-direction: column;

        .modal123-left {
            width: auto !important;
            border-radius: 2rem;
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;
            order: 2;
            padding: 4rem 1.5rem 7.5rem;

            .l-2 .l2-text {
                max-width: none !important;
            }

            .l-2 .l2-grid .ant-row {
                justify-content: flex-start;
            }
        }

        .modal1-right {
            border-radius: 2rem;
            padding: 8.5rem 1.5rem 10rem;
            width: auto !important;

            .modal1-r-heading,
            .modal1-r-subtext {
                max-width: none;
            }

        }

        .modal2-right {
            border-radius: 2rem;
            padding: 8.5rem 1.5rem 10rem;
            width: auto !important;
        }

        .modal3-right {
            border-radius: 2rem;
            padding: 8.5rem 1.5rem 10rem;
            width: auto !important;
        }

        .modal4-left {
            width: auto !important;
            border-radius: 2rem;
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;
            order: 2;
            padding: 4rem 1.5rem 7.5rem;
        }

        .modal4-right {
            border-radius: 2rem;
            padding: 8.5rem 1.5rem 10rem;
            width: auto !important;
        }

        .modal5-left {
            width: auto !important;
            border-radius: 2rem;
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;
            order: 2;
            padding: 4rem 1.5rem 7.5rem;
        }

        .modal5-right {
            border-radius: 2rem;
            padding: 3rem 1.5rem 7rem;
            width: auto !important;
        }
    }
}



@media (max-width: 768px) {
    .modal-container {
        .modal1-right {
            .modal1-r-heading {
                font-size: 1.75rem;
                line-height: 160%;
            }

            .modal1-r-subtext {
                margin-top: 3rem;
                font-size: 1rem;
                line-height: 193.5%;
            }

            .agent-val-grid .agent-val-cell {
                padding: 1.25rem 1.5rem !important;
                font-size: 1rem !important;

            }

            .cta-container {
                .begin-cta {
                    margin-top: 5rem;
                }
            }
        }

        .modal123-left {
            .l-1 {
                .l1-list-container {

                    .list-item {
                        align-items: flex-start;
                        font-size: 1rem;
                        line-height: 150%;
                        max-width: none;
                    }
                }
            }

            .l-2 {
                .l2-grid {
                    .grid-row {
                        .grid-col {
                            gap: 1.25rem;

                            .metric-text {
                                font-size: 1.09094rem;
                            }

                            .metric-val {
                                font-size: 2.291rem;
                            }

                        }
                    }
                }
            }
        }

        .modal2-right {
            .modal2-r-heading {
                font-size: 1.75rem;
                line-height: 150%;
                max-width: 18ch;
            }

            .modal2-r-subtext {
                margin-top: 3rem;
            }

            .company-val-grid {
                margin-top: 2.5rem !important;
            }

            .modal2-dropdown-container {
                margin-top: 4.5rem !important;

                .dropdown-heading {
                    font-size: 1rem;
                    line-height: 200%;
                }
            }
        }

        .modal3-right {
            .modal3-r-heading {
                font-size: 1.75rem;
                line-height: 200%;
            }

            .modal3-input-container {

                .ip1,
                .ip2,
                .ip3 {
                    .input-header {
                        font-size: 1rem;
                        line-height: 150%;
                    }

                    .ant-input {
                        padding: 0.75rem 1rem;
                    }
                }
            }

        }

        .modal4-right {
            .modal4-r-heading {
                font-size: 1.75rem;
                line-height: 150%;
            }

            .modal4-input-container {

                .ip1,
                .ip2,
                .ip3 {
                    .input-header {
                        font-size: 1rem;
                        line-height: 150%;
                    }

                    .ant-input {
                        padding: 0.75rem 1rem;
                    }
                }
            }

            .disclaimer-container {
                .disclaimer-text {
                    font-size: 0.875rem;
                    line-height: 150%;
                }
            }
        }

        .modal4-left {
            .l2-list-container {
                .list-item {
                    justify-content: flex-start !important;
                    font-size: 1rem !important;
                    line-height: 165% !important;

                }
            }
        }


        .modal5-right {

            .header {
                font-size: 1.75rem;
                line-height: 150%;
                max-width: none;
            }

            .whats-next-container {
                .header {
                    font-size: 1.25rem;
                    line-height: 150%;
                }

                .list-container {
                    padding-left: 1.5rem;

                    .list-item {
                        font-size: 1rem;
                        line-height: 168.75%;

                    }
                }
            }
        }

        .modal5-left {
            .l-2 {
                .l2-grid {
                    .grid-row {
                        .grid-col {
                            gap: 1.25rem;

                            .metric-text {
                                font-size: 1.09094rem;
                            }

                            .metric-val {
                                font-size: 2.291rem;
                            }

                        }
                    }
                }
            }
        }
    }
}