.collapse-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 38rem;

    .ant-collapse-item {
        border-radius: 2rem;
        overflow: hidden;

        &.ant-collapse-item-active {
            .ant-collapse-header {
                .collapse-header-container {
                    background: #030303;
                    color: #F0F0F0;
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;

                    .collapse-heading {
                        color: inherit;
                    }
                }
            }

            .ant-collapse-content {
                .ant-collapse-content-box {
                    background: #030303;
                    color: #F0F0F0;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                }
            }
        }

        .ant-collapse-header {
            padding: 0;
            cursor: pointer;

            .collapse-header-container {
                display: flex;
                align-items: center;
                gap: 2rem;
                background: #F0F0F0;
                border-radius: 2rem;
                padding: 2.5rem 2rem;
                color: #030303;
                transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

                .collapse-heading {
                    font-family: "Helvetica Now Display";
                    font-size: 2rem;
                    font-weight: 700;
                    line-height: 148%;
                    letter-spacing: 0.04rem;
                    text-transform: capitalize;
                    color: inherit;
                }
            }
        }

        .ant-collapse-content {
            padding: 0;

            .ant-collapse-content-box {

                background: #030303;
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 2rem;
                padding: 3rem 2rem;
                padding-top: 0rem;
                padding-left: 3.05rem;
                border-radius: 1.75rem;
                transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

                .collapse-subtext {
                    color: #CECECE;
                    max-width: 48ch;
                    font-family: 'Helvetica Neue Light';
                    // margin-top: -2.5rem;
                    // position: relative;
                    // z-index: 2;
                }
            }
        }
    }
}